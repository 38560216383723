import React, { useEffect, useRef, useState } from 'react';
import './demography.css';
import { bytesToSize, getLocalStorageValue, getRequests,abbreviateNumber, setLocalStorageValue, useForceUpdate, sidebartabAllowed, getUserType } from '../../util/util';
import FilterRow from '../../components/filterRow/filterRow';
import { getAdvertisers, getAffiliates, getManager } from '../../apis/dropdown/dropdowns';
import { Button, Tooltip } from '@mui/material';
import { Pagination } from '@mui/material';
import * as Constants from '../../data/constants';
import axios from 'axios';
import TableModel from '../../components/tableModel/tablemodel';
import ShowLoaderComponent from '../../components/loader';
import { getThisWeek } from '../../components/dateRangePicker/dateRangePicker';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import PostbacksV4 from './postbacks';
import FailedPostbacksV4 from './failedpostback';
import GoalStatsV4 from './goal';
import { DateRange } from '@mui/icons-material';
import { getFilterDict, lastQuery, updateUrlFilter } from './filterSearchAndSet';
import { Store } from 'react-notifications-component';
import { isTestingUser } from '../../data/sidebarTabs';
import { addDays,lastDayOfMonth } from 'date-fns';

const Demography = () => {
    var tempTableHeaders = []
    var isV6 = window.location.pathname.includes("statisticsV6")
    const redirectComponent = (valueItem,item) => {
        if(valueItem && valueItem.archived && valueItem[item.value]){
            return <div style={{color:'red'}}>
                        <Tooltip title={'Archived Offer'}>
                            <div style={{paddingLeft:item.value === 'offer_id' ? '15px' : '0px'}}>{valueItem[item.value]}</div>
                            {
                                item.value === 'offer_id'
                                &&
                                <div className={'archivedOfferStats'} >
                                    Archived
                                </div>
                            }
                        </Tooltip>
                </div>

        }
        if(item.link==='/offers/view/' && valueItem && valueItem.offer_id){
            var link = item.link+valueItem.offer_id
        }
        return <a className='statsV2AnchorLink' href={link} target='_blank' rel='noreferrer'>
                    {valueItem ? valueItem[item.value] : '-'}
                </a>
    }
    
    const demographyRouter = [
        {label:'Date',pathname:'daily',group_by:'date',exportType:'Stats-Daily',startTableHeader:[
            {label:'Date',value:'date',width:80,locked:true},
        ]},
        {label:'Offer',pathname:'offer',group_by:'offer_id',exportType:'Stats-Offer',startTableHeader:[
            {label:'Offer ID',value:'offer_id',width:90,link:'/offers/view/',customComponent:redirectComponent},
            {label:'Offer Name',value:'title',width:80,locked:true,link:'/offers/view/',customComponent:redirectComponent},
            {label:'Package Name',value:'package_name',width:80,locked:true},
            {label:'External Offer ID',value:'external_offer_id',width:80,locked:true},
            {label:'Advertiser',value:'advertiser_id',width:80},
            {label:'Advertiser Manager',value:'manager_id',width:80},
        ]},
        {label:'Weekly',pathname:'weekly',group_by:'week',exportType:'Stats-Weekly',startTableHeader:[
            {label:'Week',value:'week',width:160,locked:true},

        ]},
        {label:'Package Name',pathname:'day-wise-offers',group_by:'package_name',exportType:'Stats-PackageName',startTableHeader:[
            {label:'Package Name',value:'package_name',width:80,locked:true},
        ]},
        {label:'Advertiser',pathname:'adv',group_by:'advertiser_id',exportType:'Stats-Advertiser',startTableHeader:[
            {label:'Advertiser ID',value:'advertiser_id',width:80},
            {label:'Advertiser Name',value:'company',width:80,locked:true},
            {label:'Advertiser Manager',value:'manager_id',width:80},
        ]},
        {label:'Advertiser Manager',pathname:'adv-manager',group_by:'advertiser_manager_id',exportType:'Stats-AdvertiserManager',startTableHeader:[
            {label:'Manager ID',value:'advertiser_manager_id',width:80},
            {label:'Manager Name',value:'name',width:80,locked:true},
        ]},
        {label:'Affiliate',pathname:'aff',group_by:'affiliate_id',exportType:'Stats-Affiliate',startTableHeader:[
            {label:'Affiliate ID',value:'affiliate_id',width:80},
            {label:'Affiliate Name',value:'company',width:80,locked:true},
        ]},
        {label:'Affiliate Manager',pathname:'aff-manager',group_by:'affiliate_manager_id',exportType:'Stats-AffiliateManager',startTableHeader:[
            {label:'Manager ID',value:'affiliate_manager_id',width:80},
            {label:'Manager Name',value:'name',width:80,locked:true},
        ]},
        {label:'Postbacks',pathname:'conversions',customComponent:PostbacksV4},
        {label:'Event Postback',pathname:'conversions/events',customComponent:PostbacksV4},
        {label:'Failed Postbacks',pathname:'failed/postback',customComponent:FailedPostbacksV4},
        {label:'Goal',pathname:'goals',customComponent:GoalStatsV4},
    ]

    if(window.location.pathname.includes("statisticsV6")){
        var statsType = window.location.pathname.split('/statisticsV6/')[1]
        demographyRouter.splice(0,1)
        demographyRouter.splice(1,1)
        demographyRouter.splice(1,1)
        demographyRouter.splice(5,1)
        demographyRouter.splice(5,1)
        demographyRouter.splice(5,1)
        demographyRouter.splice(5,1)
    }
    else{
        var statsType = window.location.pathname.split('/statistics/')[1]
    }
    for(var statsIndex in demographyRouter){
        var xxItem = demographyRouter[statsIndex]
        if(xxItem.pathname===statsType){
            break;
        }
    }

    
    const [activeTab, setActiveTab] = useState(demographyRouter[statsIndex])
    const [loadingSeconds, setLoadingSeconds] = useState(0)
    const [responseSize, setResponseSize] = useState(0)
    const [manualUseEffect,setManualUseEffect] = useState(false)
    const [optionsAdvertiserList,setOptionsAdvertiserList] = useState([])
    const [optionsManagersList,setOptionsManagerList] = useState([])
    const [optionsAffiliateList,setOptionsAffiliateList] = useState([])
    const [loader,setLoader] = useState(false)
    const [exportLoader,setExportLoader] = useState(false)
    const [tableValues,setTableValues] = useState([])
    const [totalRow,setTotalRow] = useState({})
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [sortingData,setSortingData] = useState({sortingKeyword:statsType==='daily' ? 'date' : statsType==='weekly' ? 'week' : 'approved_revenue',sortingOrder:statsType==='weekly' ? true : false})

    var first_date_of_last_year = () => {
        return addDays(new Date(), -366-new Date().getDate()+1)
    }
    var last_date_of_last_year = () => {
        return lastDayOfMonth(addDays(new Date(), -366))
    }
    var filtersList = [
        {label:'Date Range',paramKey:'dateRange',value:'date_range',type:'daterangepicker',maxDate:(isV6 ? last_date_of_last_year() : undefined)},
        {label:'Offer Id',paramKey:'offerId',value:'offer_id',type:'textfield'},
        {label:'External Offer Id',paramKey:'externalOfferId',value:'external_offer_id',type:'textfield'},
        {label:'Package Name',paramKey:'packageName',value:'package_name',type:'textfield'},
        {label:'Advertiser',paramKey:'advertiser',value:'advertiser_id',type:'select',multiple:true,options:optionsAdvertiserList},
        {label:'Advertiser Manager',paramKey:'advertiserManager',value:'advertiser_manager_id',type:'select',multiple:true,options:optionsManagersList},
        {label:'Affiliate',paramKey:'affiliate',value:'affiliate_id',type:'select',multiple:true,options:optionsAffiliateList},
        {label:'Affiliate Manager',paramKey:'affiliateManager',value:'affiliate_manager_id',type:'select',multiple:true,options:optionsManagersList},
        {label:'Offer Source',paramKey:'offerSource',value:'manual_offer',isSingle:true,hideClearable:true,type:'select',multiple:true,options:[{label:'All Offers',value:false},{label:'Manual Offers',value:true},]},
        {label:'Statistics Type',value:'show_converted_stats',isSingle:true,hideClearable:true,type:'select',options:[{label:'All',value:undefined},{label:'Converted Stats Only',value:true},{label:'Click Stats Only',value:'clicks_only'}]},
    ]

    var defaultFilterData = {
        from_date:isV6 ? first_date_of_last_year() : new Date(new Date().getTime() - 86400000*(statsType==='weekly' ? (27+new Date().getDay()) : 6)),
        end_date:isV6 ? last_date_of_last_year() : new Date(),
        show_converted_stats:{label:'Converted Stats Only',value:true,default:true},
    }
    const [filterData, setFilterData] = useState({...defaultFilterData,...getFilterDict(filtersList)})
    
    const getStatsData = async(e,manual_limit=null,manual_page=null,sortingKeyword=null,sortingOrder=null,manual_group_by=null) => {
        if(e!=='export'){
            setLoader(true);
        }
        else{
            setExportLoader(true)
        }
        var start_time = new Date().getTime()
        var from_date = filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        
        var filter_dict = {}
        for(var index in filtersList){
            var item = filtersList[index]
            if(item.type==='textfield'){
                if(filterData[item.value]){
                    filter_dict[item.value] = filterData[item.value]
                }
            }
            if(item.type==='select' && !item.isSingle){
                var value = ''
                for(var xIndex in filterData[item.value]){
                    var xItem = filterData[item.value][xIndex]
                    if(parseInt(xIndex)===filterData[item.value].length-1){
                        value+= xItem.value
                    }
                    else{
                        value+= xItem.value + ','
                    }
                }
                if(value || value===0){
                    filter_dict[item.value] = value
                }
            }
            if(item.type==='select' && item.isSingle){
                if(filterData[item.value] && filterData[item.value].value){
                    filter_dict[item.value] = filterData[item.value].value
                }
            }
        }
        var url = "".concat(Constants.PROTOCOL2,Constants.HOST2,'/api/admin/v4/statistics')
        if(window.location.pathname.includes("statisticsV6")){
            url = "".concat(Constants.PROTOCOL2,Constants.HOST2,'/api/admin/v6/statistics')
        }
        var method = 'GET'
        var params = {
            from_date,
            end_date,
            group_by : manual_group_by ? manual_group_by : activeTab.group_by,
            order_key : 'clicks',
            order_by : 'desc',
            limit: manual_limit ? manual_limit : limit,
            page: manual_page ? manual_page : page,
            order_key: sortingKeyword ? sortingKeyword : sortingData.sortingKeyword ? sortingData.sortingKeyword : 'total_revenue',
            order_by:sortingOrder ? sortingOrder : sortingData.sortingOrder ? 'asc': 'desc',
            use_monthly_summary:window.location.pathname.includes("statisticsV6") ? true : undefined,
            ...filter_dict
        }
        var data = {}
        
        if(e==='export'){
            url = "".concat(Constants.PROTOCOL2,Constants.HOST2,'/api/admin/v4/statistics/export')
            if(window.location.pathname.includes("statisticsV6")){
                url = "".concat(Constants.PROTOCOL2,Constants.HOST2,'/api/admin/v6/statistics/export')
            }    
            method = 'POST'
            var columns_requested = []
            for(var index in tempTableHeaders){
                var item = tempTableHeaders[index]
                columns_requested.push(item.value)
            }
            data = {
                ...params,
                stats_type:activeTab.exportType,
                columns_requested
            }
            params = {}
        }
        await axios({
            method,
            url,
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data,
            params
        })
        .then(async(resp) => {
            if(e==='export'){
                Store.addNotification({
                    title: "Success!",
                    message: 'Export Request has been created, please check Export Section to download the export file.',
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
            else{
                var items = resp.data.result
                setTableValues(items)
                setTotalRow([resp.data.total_row])
                setTotalResults(resp.data.total_no_of_result)
                const resSize = +resp?.headers?.['content-length']
                setResponseSize(resSize)
            }
        })
        .catch((error) => {
            setTableValues([])
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data){
                    setTableValues(error.response.data.result);
                }
                else{
                    setTableValues('Some error occured at backend, please contact your Account Manager.');
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                setTableValues('Some error occured at backend, please contact your Account Manager.');
              }
        })
        setLoader(false);
        setExportLoader(false);
        var end_time = new Date().getTime()
        setLoadingSeconds((end_time-start_time)/1000)
    }

    useEffect(() => {
        if(!activeTab.customComponent){
            getStatsData()
        }
    }, [])
    
    if(!manualUseEffect){
        setManualUseEffect(true)
        getRequests(getAdvertisers(),setOptionsAdvertiserList)
        getRequests(getManager(),setOptionsManagerList)
        getRequests(getAffiliates(),setOptionsAffiliateList)
    }


    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            setFilterData({...filterData,...value})
        }
        else if(filterItem.value==='show_converted_stats'){
            setFilterData({...filterData,[filterItem.value]:value})
        }
        else{
            if(filterData.show_converted_stats && filterData.show_converted_stats.default){
                var sc = {label:'All',value:false}
            }
            else{
                var sc = filterData.show_converted_stats
            }
            setFilterData({...filterData,show_converted_stats:sc,[filterItem.value]:value})
        }
    }

    const roundComponent = (valueItem,item) => {
        if(parseFloat(valueItem[item.value])){
            return valueItem[item.value].toFixed(2)
        }
        return valueItem[item.value]
    }

    const abbreviateNumberComponent = (valueItem,item) => {
        if(valueItem[item.value]){
            return valueItem[item.value]
        }
        return 0
    }

    const postbackRedirectionComponent = (valueItem,item) => {
        if(valueItem[item.value]){
            var link = '/statistics/conversions?type='+item.value+'&'
            var link2 = '/statistics/conversions?type='+item.value+'&'
            if(activeTab.group_by==='date' && valueItem[activeTab.group_by]){
                link += 'date_range='+valueItem[activeTab.group_by]+'_'+valueItem[activeTab.group_by]
            }
            else{
                link += 'date_range='+item.filterDataRef.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})+'_'+item.filterDataRef.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})+'&'
            }
            if(activeTab.group_by==='offer_id' && valueItem[activeTab.group_by]!=='Total'){
                link += 'offer_id='+valueItem[activeTab.group_by]
            }
            else if(['advertiser_id','affiliate_id'].includes(activeTab.group_by) && valueItem[activeTab.group_by]!=='Total'){
                link += activeTab.group_by+'='+valueItem.company+'-'+valueItem[activeTab.group_by]
            }
            else if(['advertiser_manager_id','affiliate_manager_id'].includes(activeTab.group_by) && valueItem[activeTab.group_by]!=='Total'){
                link += activeTab.group_by+'='+valueItem.name+'-'+valueItem[activeTab.group_by]
            }
            if(item.value === 'declined'){
                link += '&group_by=goal_value' 
            }
            if(activeTab.group_by==='date' || valueItem[activeTab.group_by]==='Total'){
                var url = updateUrlFilter(filtersList,filterData).split('?')[1]
                var url_params = {}
                for(var ii in url.split('&')){
                    var it = url.split('&')[ii]
                    if(it.includes('=')){
                        url_params[it.split('=')[0]]=it.split('=')[1]
                    }
                }
                for (const [key, value] of Object.entries(url_params)) {
                    if(key!=='date_range'){
                        link += '&'+key+'='+value+'&'
                    }
                }
            }
            if(link!==link2){
                return <a className='statsV2AnchorLink' href={link} target='_blank' rel='noreferrer'>
                    {valueItem[item.value]}
                </a>
            }
            else{
                return valueItem[item.value]
            }

        }
        return 0
    }

    var endTempTableHeaders = [
        {label:'Clicks',value:'clicks',width:80,customComponent:abbreviateNumberComponent},
        {label:'Trafficback',value:'trafficback',width:100,customComponent:abbreviateNumberComponent},
        {label:'Total Traffic',value:'total_clicks_trafficback',width:100,customComponent:abbreviateNumberComponent},
        {label:'CR',value:'cr',width:65,customComponent:roundComponent},
        {label:'EPC',value:'epc',width:65,customComponent:roundComponent},
        {label:'Delivered Qty',value:'approved',width:120,customComponent:postbackRedirectionComponent,filterDataRef:filterData},
        {label:'Delivered Revenue',value:'approved_revenue',width:120,customComponent:roundComponent},
        {label:'Delivered Payout',value:'approved_payout',width:120,customComponent:roundComponent},
        {label:'Declined Qty',value:'declined',width:100,customComponent:postbackRedirectionComponent,filterDataRef:filterData},
        {label:'Declined Revenue',value:'declined_revenue',width:100,customComponent:roundComponent},
        {label:'Declined Payout',value:'declined_payout',width:100,customComponent:roundComponent},
        {label:'Total Qty',value:'total_quantity',width:100,customComponent:abbreviateNumberComponent},
        {label:'Total Revenue',value:'total_revenue',width:100,customComponent:roundComponent},
        {label:'Total Payouts',value:'total_payout',width:100,customComponent:roundComponent},
        {label:'Total Earnings',value:'total_earnings',width:100,customComponent:roundComponent},
    ]

    if(!activeTab.customComponent){
        tempTableHeaders = [
            ...activeTab.startTableHeader,
            ...endTempTableHeaders
        ]
    }
    else{
        tempTableHeaders = []
    }

    // const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)

    if(activeTab.customComponent){
        return <div className='demographyContainer'>
                    <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
                        <div className='demographyRouterRow'>
                            {demographyRouter.map((item,index)=>{
                                if(sidebartabAllowed('/statistics/'+item.pathname) || (item.group_by==='package_name' && (getUserType()==='general_manager' || getUserType()==='account_manager'))){
                                    return <NavLink to={lastQuery(item)} style={{background:(activeTab.label === item.label ? getLocalStorageValue('ambientColor') : 'rgb(245,245,245)'),color:(activeTab.label === item.label ? 'rgb(255,255,255)' : 'black'),textDecoration:'none'}} className='demographyRouterItem' key={index} onClick={()=>{setActiveTab(item);getStatsData(null,null,null,null,sortingData.sortingOrder,item.group_by)}}>
                                                    {item.label}
                                                </NavLink>
                                        }
                            })}
                        </div>
                    </div>
                    <activeTab.customComponent optionsAdvertiserList={optionsAdvertiserList} optionsManagersList={optionsManagersList} optionsAffiliateList={optionsAffiliateList}/>                    
                </div>
    }


    const sortingFunction = (keyword,sortingOrder) => {
        if(totalResults<=limit){
            
        }
        else{
            getStatsData(null,null,null,keyword,sortingOrder)
        }
        setSortingData({sortingKeyword:keyword,sortingOrder})
    }
    const customDateRangeFunction = (item,showDateRangePicker,setShowDateRangePicker) => {
            
        function isBeforeSixMonths(date) {
            var sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 0);
            return date < sixMonthsAgo;
        }
        function getStartOfMonth(date) {
            return new Date(date.getFullYear(), date.getMonth(), 1);
        }
        
        function getEndOfMonth(date) {
            return new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }
        setShowDateRangePicker(showDateRangePicker+1)
        var value = [item.selection.startDate,item.selection.endDate]

        if(isBeforeSixMonths(item.selection.startDate)){
            value = [
                getStartOfMonth(item.selection.startDate),
                getEndOfMonth(item.selection.startDate)
            ]
        }
        overrideSetFilterData({from_date:value[0],end_date:value[1]},'dateRangePicker')
        
        if(showDateRangePicker===2 || isBeforeSixMonths(item.selection.startDate)){
            setShowDateRangePicker(0)
        }
    }

    return (
        <div className='demographyContainer'>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
                {!loader && <div style={{position:'absolute',fontSize:'10px',left:5,bottom:-45}}>Data Loaded in {loadingSeconds} sec {responseSize ? '('+bytesToSize(responseSize)+')' : ''}</div>}
                <div className='demographyRouterRow'>
                    {demographyRouter.map((item,index)=>{
                        if(sidebartabAllowed('/statistics/'+item.pathname) || (item.group_by==='package_name' && (getUserType()==='general_manager' || getUserType()==='account_manager'))){
                            return <NavLink to={lastQuery(item)} style={{background:(activeTab.label === item.label ? getLocalStorageValue('ambientColor') : 'rgb(245,245,245)'),color:(activeTab.label === item.label ? 'rgb(255,255,255)' : 'black'),textDecoration:'none'}} className='demographyRouterItem' key={index} onClick={()=>{setActiveTab(item);getStatsData(null,null,null,null,sortingData.sortingOrder,item.group_by)}}>
                                    {item.label}
                                </NavLink>
                        }
                    })}
                </div>
                <div className='demographyRouterWrapper'>
                    <div className='demographyFilterRow'>
                        <div className='demographyFilterAddRow'>
                            <FilterRow 
                                onEnterFunction={()=>{getStatsData(0,limit,1)}}
                                customDateRangeFunction={window.location.pathname.includes("statisticsV6") ? customDateRangeFunction : undefined} //#commented by deepesh, what is does is select the whole if date is previous then 5 months
                                filtersList={filtersList} 
                                filterData={filterData} 
                                setFilterData={overrideSetFilterData}
                            />
                        </div>
                        <div>
                            <Button 
                                onClick={(e)=>{getStatsData(e,limit,1); window.history.pushState('Statistics - '+activeTab.label, 'Statistics - ' +activeTab.label, updateUrlFilter(filtersList,filterData));}}
                                sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}}>
                                Search
                            </Button>
                            <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left'}} onClick={()=>{setFilterData(defaultFilterData)}}>Reset</Button>

                        </div>
                        <div style={{marginTop:'20px',fontSize:'12px',cursor:'pointer',width:'max-content'}} onClick={()=>{localStorage.setItem('statsV2',true);window.location.reload();}}>
                            Switch To Old Design
                        </div>
                    </div>
                </div>
            </div>
            {loader
            &&
            <ShowLoaderComponent/>
            }
            {!loader
                &&
                <TableModel
                    tableHeaders={tempTableHeaders}
                    topRowStyles={{background:'rgb(230,230,230)'}}
                    serialOffset={offset}
                    // setTableHeaders={setTableHeaders}
                    tableValues={tableValues}
                    setTableValues={setTableValues}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    totalRow={totalRow.length>0 ? totalRow : undefined}
                    setTotalRow={setTotalRow}
                    sortingFunction={sortingFunction}
                    exportFunction={exportLoader ? 'loading' : ()=>{getStatsData('export',limit,1)}}
                    />
                }
            {!loader && totalResults/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:1000}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,10,50,100,200,300,500].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
            <br/>
            <br/>
        </div>
    )
}

export default Demography