import { Resizable } from 're-resizable';
import React, { useEffect, useState } from 'react';
import './tablemodel.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CloseOutlined, Download, Info, InfoOutlined, RestartAltSharp, Settings } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import SomeErrorOccured from '../someErrorOccured';
import NoResultsFound from '../noResultFound';
import FilterListIcon from '@mui/icons-material/FilterList';

export const increase_brightness = function(color,percent){

    var ctx = document.createElement('canvas').getContext('2d');

    ctx.fillStyle = color;
    ctx.fillRect(0,0,1,1);

    color = ctx.getImageData(0,0,1,1);
    var r = color.data[0] + Math.floor( percent / 100 * 255 );
    var g = color.data[1] + Math.floor( percent / 100 * 255 );
    var b = color.data[2] + Math.floor( percent / 100 * 255 );

    return 'rgb('+r+','+g+','+b+')';
}
const TableModel = (props) => {
    const refreshComponent = useForceUpdate();
    var tempTableHeaders = []
    for(var index in props.tableHeaders){
        var item =  props.tableHeaders[index]
        var containsChild = item.child ? true : false
        tempTableHeaders.push({...item,minWidth:item.width})
    }
    if(localStorage.getItem(props.id ? props.id : window.location.pathname)){
        tempTableHeaders = JSON.parse(localStorage.getItem(props.id ? props.id : window.location.pathname))
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    if(props.tableValues){
        var tableValues = props.tableValues
        var setTableValues = props.setTableValues
    }
    const [sortingOrderKeyValue, setSortingOrderKeyValue] = useState({})
    const [showTableEditPopup, setShowTableEditPopup] = useState(false)
    const [showFilterComponent, setShowFilterComponent] = useState(false)
    
    const lockedItemCss = (item,header) => {
        if(item.locked){
            return {position:(item.locked ? 'sticky' : 'relative'),left:(props.showCheckbox ? 70 : 40),zIndex:(header ? 10 : 1)}
        }
        return {}
    }
    
    const sortingFunction = (keyword,sortingOrder) => {
        if(props.sortingFunction){
            props.sortingFunction(keyword,sortingOrder)
        }
        setSortingOrderKeyValue({[keyword]:sortingOrder})
        var tempTableValues = tableValues
        sortingV2(tempTableValues,keyword,sortingOrder)
        setTableValues(tempTableValues)
    }
    const sortingComponent = (isChild,item,childItem) => {
        var sortingKey = childItem ? childItem.value : item.value
        var ascendingActive = Object.keys(sortingOrderKeyValue)[0]===sortingKey && sortingOrderKeyValue[Object.keys(sortingOrderKeyValue)[0]] === true
        var descendingActive = Object.keys(sortingOrderKeyValue)[0]===sortingKey && sortingOrderKeyValue[Object.keys(sortingOrderKeyValue)[0]] !== true
        return <div style={{position:'absolute',right:(isChild ? 0 : 5),cursor:'pointer',display:'flex',flexDirection:'column'}}>
                    <KeyboardArrowUpIcon className='sortingIcon' sx={{fontSize:(isChild ? '12px' : '14px'),height:'14px',marginBottom:'-1px',color:(ascendingActive ? 'rgb(10,10,10)' : 'rgb(170,170,170)')}} onClick={()=>{sortingFunction(sortingKey,true);}}/>
                    <KeyboardArrowDownIcon className='sortingIcon' sx={{fontSize:(isChild ? '12px' : '14px'),color:(descendingActive ? 'rgb(10,10,10)' : 'rgb(170,170,170)')}} onClick={()=>{sortingFunction(sortingKey,false);}}/>
                </div>
    }

    const getHeaderRowHeight = () => {
        if(document.getElementById('tableHeaderRow')){
            var headerRowHeight = document.getElementById('tableHeaderRow').getBoundingClientRect().height
        }
        else if(headerRowHeightState){
            headerRowHeight = headerRowHeightState
        }
        else{
            headerRowHeight = '72.8px'
        }
        return headerRowHeight
    }
    const filterComponent = (tableHeaderIndex) => {
        var marginLeft = -100
        if(showFilterComponent.e){
            if((showFilterComponent.e.screenX + 200) > window.innerHeight){
                marginLeft = -(window.innerHeight - showFilterComponent.e.screenX) + 100
            }
        }
        return <div className='filterIcon' onClick={(e)=>{setShowFilterComponent({e,tableHeaderIndex})}}>
            <FilterListIcon sx={{color:'rgb(100,100,100)',fontSize:'16px'}}/>
            {showFilterComponent.tableHeaderIndex===tableHeaderIndex
            &&
            <div className='tableModelFilterContainer' style={{marginLeft,marginTop:(getHeaderRowHeight()/2)}}>

            </div>
            }
        </div>
    }
    
    const tableHeaderFunction = (showChildOnly) => {
        return props.tableHeaders.map((item,index)=>{
            if(tableHeaders[index] && !tableHeaders[index].hide){
                if(tableHeaders[index]){
                    var width = tableHeaders[index].width
                }
                else{
                    width = item.width
                    var tempTableHeadersState = tableHeaders
                    tempTableHeadersState.push(item)
                    setTableHeaders(tempTableHeaders)
                    refreshComponent();
                }

                if(width>item.maxWidth){
                    width=item.maxWidth
                }

                if(item.style){
                    var style=item.style
                }
                else{
                    style = {}
                }
                return <td className='tableHeaderStyling' style={{...lockedItemCss(item,true),minWidth:width,width,position:'sticky',top:props.valuesCheckbox && props.valuesCheckbox.length>0 ? '50px' : 0,zIndex:(item.locked ? 10 : 9),...style}} key={index}>
                    <Resizable
                        size={{width:'100%'}}
                        style={{transition:'all 0.3s linear',paddingLeft:'2px',paddingRight:'2px'}}
                        enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                        onResizeStop={(e, direction, ref, d) => {
                            var tempTableHeader = tableHeaders
                            var tempColumnWidth = width
                            if(tempColumnWidth + d.width < item.width){
                                tempColumnWidth = item.width
                            }
                            else{
                                tempColumnWidth = tempColumnWidth + d.width
                            }
                            tempTableHeader[index].width = tempColumnWidth;
                            setTableHeaders(tempTableHeader);
                            refreshComponent(e);
                            }}
                            >
                                <div className='tableHeaderDiv'>
                                    {
                                        item.customHeaderComponent
                                        &&
                                        item.customHeaderComponent(item,index,sortingComponent)
                                    }
                                    {
                                        !item.customHeaderComponent
                                        &&
                                        <div style={!item.child ? {width:'calc(100% - 20px'} : {width:'100%'}}>
                                            <div className='tableHeaderLabel' style={{transition:'all 0.3s linear'}}>
                                                <div style={{display:'flex',position:'relative',justifyContent:item.child ? 'center' : 'flex-start'}}>
                                                    {item.label}
                                                    {
                                                        item.info
                                                        &&
                                                        <div style={{marginTop:'-5px',marginRight:'-12px'}}>
                                                            <Tooltip title={item.info}>
                                                                <InfoOutlined sx={{fontSize:'12px'}}/>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {item.child &&
                                                <table style={{fontSize:'12px',paddingTop:'10px',textAlign:'center',width:"100%",tableLayout:'fixed'}}>
                                                    <tr>
                                                        {item.child.map((childItem,childIndex)=>{
                                                            if(!tableHeaders[index].child[childIndex].hide){
                                                                let width = childItem.width ? childItem.width+'%' : parseFloat(100/getActiveChildLength(item.child))+'%'
                                                                return <td key={childIndex}  style={{width:width,minWidth:width}}>
                                                                    <div style={{display:"flex",justifyContent:'center',alignItems:'center',minWidth:'100%',overflow:'clip',position:'relative'}}> 
                                                                        <div className='tableHeaderLabel' style={{width:'80%'}}>{childItem.label}</div>
                                                                        {
                                                                        !childItem.hideSortingChild
                                                                        &&
                                                                        sortingComponent(true,item,childItem)
                                                                        }
                                                                    </div>
                                                                </td>
                                                            }
                                                        })}
                                                    </tr>
                                                </table>
                                            }
                                        </div>
                                    }
                                    {!item.customHeaderComponent && !item.child && !item.hideSorting
                                    &&
                                    sortingComponent(false,item)
                                    }
                                </div>
                            </Resizable>
                </td>
            }
        })
    }
    const [headerRowHeightState, setHeaderRowHeightState] = useState(0)
    const tableTotalValueFunction = (showChildOnly) => {
        return props.tableHeaders.map((item,index)=>{
            if(tableHeaders[index] && !tableHeaders[index].hide){
                var valueItem = props.totalRow[0]
                if(tableHeaders[index]){
                    var width = tableHeaders[index].width
                }
                else{
                    width = item.width
                    var tempTableHeadersState = tableHeaders
                    tempTableHeadersState.push(item)
                    setTableHeaders(tempTableHeaders)
                    refreshComponent();
                }

                if(width>item.maxWidth){
                    width=item.maxWidth
                }
                
                var headerRowHeight = getHeaderRowHeight()
                
                if(item.style){
                    var style=item.style
                }
                else{
                    style = {}
                }
                if(item.customComponent){
                    return <td className='tableTotalValueStyling' style={{...lockedItemCss(item,true),minWidth:width,width,transition:'all 0.3s linear',position:'sticky',top:headerRowHeight,zIndex:(item.locked ? 10 : 9),color:'white',...style}} key={index}>
                        {item.customComponent(valueItem,item,index,0,props.tableValues)}
                    </td>
                }
                return <td className='tableTotalValueStyling' style={{...lockedItemCss(item,true),minWidth:width,width,position:'sticky',top:headerRowHeight,zIndex:(item.locked ? 10 : 9),color:'white',...style}} key={index}>                                 
                    {!item.child && valueItem[item.value]}
                    {item.child &&
                        <table style={{fontSize:'12px',textAlign:'center',width:"100%",tableLayout:'fixed'}}>
                            <tr>
                                {item.child.map((childItem,childIndex)=>{
                                    if(!childItem.hide){
                                        let childWidth = childItem.width ? childItem.width+'%' : (100/getActiveChildLength(item.child))+'%'
                                        if(childItem.customComponent){
                                            return <td key={childIndex}  style={{width:childWidth,minWidth:childWidth}}>
                                                <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                                                    {childItem.customComponent(valueItem,childItem,childIndex)}
                                                </div>
                                            </td>
                                        }
                                        return <td key={childIndex}  style={{width:childWidth,minWidth:childWidth}}>
                                                    <div key={childIndex} style={{display:"flex",justifyContent:'center',alignItems:'center',minWidth:'100%',overflow:'clip'}}>
                                                        {valueItem[childItem.value]}
                                                    </div>
                                                </td>
                                    }
                                    })
                                    }
                            </tr>
                        </table>
                    }
                </td>

            }
        })
    }
    useEffect(() => {
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                props.setTableValueRow(false)
            }
        })
        if(document.getElementById('tableHeaderRow')){
            setHeaderRowHeightState(document.getElementById('tableHeaderRow').getBoundingClientRect().height)
            refreshComponent(12)
        }
        return () => {
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    props.setTableValueRow(false)
                }
            })
            if(document.getElementById('tableHeaderRow')){
                setHeaderRowHeightState(document.getElementById('tableHeaderRow').getBoundingClientRect().height)
                refreshComponent(12)
            }
        }
    }, [props,setHeaderRowHeightState])
    
    const getActiveChildLength = (childs) => {
        var childActive = childs.length
        childs.forEach(childItem=>{
            if(childItem.hide){
                childActive -=1
            }
        })
        return childActive
    }
    const tableValuesFunctions = (tableValuesData,totalRow=false) => {
        if(typeof tableValuesData === 'object'){
            return tableValuesData.map((valueItem,valueIndex)=>{
                return <tr className={'tableValueRowStyling'} style={totalRow ? {background:'orange',position:'sticky',bottom:'50px'} : {}} key={valueIndex}>
                    {props.showCheckbox
                    &&
                    <td className='tableValueStyling' style={{position:'sticky',top:0,left:0,minWidth:'10px',width:'10px',maxWidth:'10px',zIndex:20,background:(valueIndex%2!==0 ? 'var(--ambient-color-90-percent-lighter)' : 'white')}} onClick={()=>{var tempSelected = props.valuesCheckbox ? props.valuesCheckbox : [];if(props.valuesCheckbox && props.valuesCheckbox.includes(parseInt(valueIndex))){tempSelected = tempSelected.filter(e => e!==parseInt(valueIndex));props.setValuesCheckbox(tempSelected);return}else{props.setValuesCheckbox([...tempSelected,valueIndex])}}} >
                        <input checked={props.valuesCheckbox && props.valuesCheckbox.includes(parseInt(valueIndex))} type='checkbox'></input>
                    </td>
                    }
                    <td className='tableValueStyling' style={{position:'sticky',left:(props.showCheckbox ? 31 : 0),minWidth:'30px',maxWidth:'30px',zIndex:2,background:(valueIndex%2!==0 ? 'var(--ambient-color-90-percent-lighter)' : 'white')}}>{valueIndex+1 + (props.serialOffset ? props.serialOffset : 0)}</td>
                    {props.tableHeaders.map((item,index)=>{
                        if(tableHeaders[index] && !tableHeaders[index].hide){
                            if(tableHeaders[index]){
                                var width = tableHeaders[index].width
                            }
                            else{
                                width = item.width
                                var tempTableHeadersState = tableHeaders
                                tempTableHeadersState.push(item)
                                setTableHeaders(tempTableHeaders)
                                refreshComponent();
                            }
                
                            if(width>item.maxWidth){
                                width=item.maxWidth
                            }
                            if(item.style){
                                var style=item.style
                            }
                            else{
                                style = {}
                            }
                            if(item.valueStyle){
                                style=item.valueStyle
                            }
                            if(props.valueRowHeight){
                                style = {...style,height:props.valueRowHeight,maxHeight:props.valueRowHeight,paddingTop:0,paddingBottom:0,padding:0,paddingLeft:'5px',paddingRight:'5px'}
                            }
                            if(item.customComponent){
                                return<td className='tableValueStyling' style={{height:'2.5rem',position:'sticky',left:(item.locked ? (props.showCheckbox ? 70.5 : 40.5) : 0),background:(item.locked ? valueIndex%2!==0 ? 'var(--ambient-color-90-percent-lighter)' : 'white' : valueIndex%2!==0 ? 'var(--ambient-color-90-percent-lighter)' : 'white'),zIndex:(item.locked ? 1 : 0),maxWidth:width,...style}} key={index}>    
                                    {item.customComponent(valueItem,item,index,valueIndex)}
                                </td>
                            }
                            return <td className='tableValueStyling' style={{position:(item.locked ? 'sticky' : ''),left:(item.locked ? (props.showCheckbox ? 70.5 : 40.5) : 0),background:(item.locked ? valueIndex%2!==0 ? 'var(--ambient-color-90-percent-lighter)' : 'white' : valueIndex%2!==0 ? 'var(--ambient-color-90-percent-lighter)' : 'white'),zIndex:(item.locked ? 1 : 0),maxWidth:width,...style}} key={index}>                                    
                                {!item.child && 
                                    valueItem[item.value]
                                }
                                {item.child &&
                                    <table style={{fontSize:'12px',textAlign:'center',width:"100%",tableLayout:'fixed'}}>
                                        <tr>
                                            {item.child.map((childItem,childIndex)=>{
                                                    if(!childItem.hide){
                                                        let childWidth = childItem.width ? childItem.width+'%' : (100/getActiveChildLength(item.child))+'%'
                                                        if(childItem.customComponent){
                                                            return <td key={childIndex}  style={{width:childWidth,minWidth:childWidth}}>
                                                                <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                                                                    {childItem.customComponent(valueItem,childItem,childIndex,item)}
                                                                </div>
                                                            </td>
                                                        }
                                                        return <td key={childIndex}  style={{width:childWidth,minWidth:childWidth}}>
                                                                    <div key={childIndex} style={{display:"flex",justifyContent:'center',alignItems:'center',minWidth:'100%',overflow:'clip'}}>
                                                                        {valueItem[childItem.value]}
                                                                    </div>
                                                                </td>
                                                    }
                                                })}
                                        </tr>
                                    </table>
                                }
                                
                            </td>
                        }
                    })}
                </tr>
            })
        }
    }
    const changeTableHeaders = (item,hidden=false) => {
        var tempTableHeaders = []
        for(var tableHeaderIndex in tableHeaders){
            var tableHeaderItem =  tableHeaders[tableHeaderIndex]

            if(tableHeaderItem.child){
                for(var childTableHeaderIndex in tableHeaderItem.child){
                    var childTableHeaderItem = tableHeaderItem.child[childTableHeaderIndex]
                    if(item.label === childTableHeaderItem.label){
                        childTableHeaderItem['hide'] = hidden
                    }
                }
            }
            if(item.label === tableHeaderItem.label){
                tableHeaderItem['hide'] = hidden
            }
            tempTableHeaders.push(tableHeaderItem)
        }
        if(document.getElementById('tableHeaderRow')){
            setHeaderRowHeightState(document.getElementById('tableHeaderRow').getBoundingClientRect().height)
            refreshComponent(12)
        }
        localStorage.setItem(props.id ? props.id : window.location.pathname,JSON.stringify(tempTableHeaders))
        setTableHeaders(tempTableHeaders)
        refreshComponent(12)
    }
    const editTableFunction = () => {
        var typeTraversed = []

        return <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:'20px',paddigBottom:'20px',position:'relative'}}>
            <table style={{tableLayout:'fixed',width:'100%'}}>
                <thead>
                    <tr style={{position:'sticky',top:0,background:'white'}}>
                        <td style={{padding:'10px',fontWeight:'500',fontSize:'16px'}}>
                            Column Name
                        </td>
                        <td style={{padding:'10px',fontWeight:'500',fontSize:'16px'}}>
                            Show
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {tableHeaders.map((item,index)=>{
                        if(item.child){
                            if(!typeTraversed.includes(item.type)){
                                typeTraversed.push(item.type)
                                return item.child.map((childItem,childIndex)=>{
                                    return <tr key={childIndex}>
                                        <td style={{padding:'4px',fontSize:'13px',paddingLeft:'10px'}}>
                                            {childItem.label}
                                        </td>
                                        <td style={{padding:'4px',fontSize:'13px',paddingLeft:'10px'}}>
                                            <input type='checkbox' checked={!childItem.hide} onChange={(e)=>{changeTableHeaders(childItem,!e.target.checked)}}/>
                                        </td>
                                    </tr>
                                })
                            }
                        }
                        if(!item.child){
                            return <tr key={index}>
                                <td style={{padding:'4px',fontSize:'13px',paddingLeft:'10px'}}>
                                    {item.label}
                                </td>
                                <td style={{padding:'4px',fontSize:'13px',paddingLeft:'10px'}}>
                                    <input type='checkbox' checked={!item.hide} onChange={(e)=>{changeTableHeaders(item,!e.target.checked)}}/>
                                </td>
                            </tr>
                        }
                    })}
                </tbody>
            </table>
        </div>
    }
    const topRowStyles = () => {
        return props.topRowStyles ? props.topRowStyles : {}
    }
    if(typeof tableValues === 'object' && tableValues.length===0){
        return <div style={{marginTop:'50px'}}>
            <NoResultsFound/>
        </div>
    }
    if(typeof tableValues === 'string'){
        return <div style={{marginTop:'50px'}}>
                <SomeErrorOccured error={tableValues}/>
            </div> 
    }
    return (
        <>         
            {
                !props.customTableSettingsButton &&
                <div style={{borderBottom:'1px solid rgb(200,200,200)',position:'sticky',left:0,display:'flex',justifyContent:'right',alignItems:'center',minHeight:'40px',padding:'20px',paddingTop:'5px',paddingBottom:'5px',background:'rgb(255,255,255)',...topRowStyles()}}>
                    {
                        props.optionsTopbar &&
                        props.optionsTopbar.map((item)=>{
                            return item
                        })
                    }
                    <IconButton onClick={()=>{setShowTableEditPopup(true)}}>
                        <Tooltip title='Edit table settings'>
                            <Settings/>
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={()=>{localStorage.removeItem(props.id ? props.id : window.location.pathname)}}>
                        <Tooltip title='Reset table settings'>
                            <RestartAltSharp/>
                        </Tooltip>
                    </IconButton>
                    {props.exportFunction && props.exportFunction === 'loading'
                        &&
                        <IconButton>
                            <Tooltip title='Request Process, please wait.'>
                                <CircularProgress size={'18px'} sx={{color:localStorage.getItem('ambientColor')}}/>
                            </Tooltip>
                        </IconButton>
                    }
                    {props.exportFunction && props.exportFunction !== 'loading'
                        &&
                        <IconButton onClick={()=>{props.exportFunction()}}>
                            <Tooltip title='Export Data to CSV'>
                                <Download/>
                            </Tooltip>
                        </IconButton>
                    }
                </div>
            }
            {
                showTableEditPopup &&
                <div style={{position:"fixed",top:0,left:80,height:'100vh',width:'calc(100vw - 80px)',display:'flex',alignItems:'center',justifyContent:'center',zIndex:'10000',background:'rgb(200,200,200,0.1)',backdropFilter:'blur(1px)'}} onClick={()=>{setShowTableEditPopup(false);}}>
                    <div style={{display:'flex',height:'50vh',width:'50vw',background:'white',flexDirection:'column'}} onClick={(e)=>{e.stopPropagation()}}>
                        <div style={{display:'flex',height:'50px',width:'calc(100% - 40px)',justifyContent:'space-between',alignItems:'center',paddingLeft:'20px',paddingRight:'20px'}}>
                            <div>Edit Table Settings</div>
                            <div style={{cursor:'pointer'}} onClick={()=>{setShowTableEditPopup(false);}}><CloseOutlined/></div>
                        </div>
                        <div style={{height:'calc(100% - 90px)',overflow:'auto'}}>
                            {editTableFunction()}
                        </div>
                    </div>
                </div>
            }
            <table style={{tableLayout:'auto',width:'100%',borderSpacing:'0px',overflow:'auto',...props.style}}>
                <tbody>
                    <tr id='tableHeaderRow'>
                        {props.showCheckbox && 
                            <td className='tableHeaderStyling' style={{top:props.valuesCheckbox && props.valuesCheckbox.length>0 ? '50px' : '0',position:'sticky',left:0,zIndex:21,minWidth:'25px',width:'25px',maxWidth:'25px'}}>
                                <input checked={tableValues && props.valuesCheckbox ? tableValues.length===props.valuesCheckbox.length : false} onChange={()=>{if(tableValues && props.valuesCheckbox ? tableValues.length===props.valuesCheckbox.length : false){props.setValuesCheckbox([]);return;};var selectall = []; for(var i=0;i<tableValues.length;i++){selectall.push(i);}props.setValuesCheckbox(selectall);}} type='checkbox'></input>
                            </td>
                        }
                        <td className='tableHeaderStyling' style={{position:'sticky',top:props.valuesCheckbox && props.valuesCheckbox.length>0 ? '50px' : 0,left:(props.showCheckbox ? 31 : 0),zIndex:20,minWidth:'30px',width:'30px',maxWidth:'30px'}}>#</td>
                        {tableHeaderFunction()}
                    </tr>
                    {props.totalRow && 
                    <tr>
                        {props.showCheckbox
                        &&
                        <td className='tableTotalValueStyling'  style={{position:'sticky',top:getHeaderRowHeight(),left:0,minWidth:'10px',width:'10px',maxWidth:'10px',zIndex:21,color:'white'}}>
                        </td>
                        }
                        <td className='tableTotalValueStyling'  style={{position:'sticky',top:getHeaderRowHeight(),left:(props.showCheckbox ? 31 : 0),zIndex:20,color:'white'}}>#</td>
                        {tableTotalValueFunction()}
                    </tr>
                    }
                    {tableValuesFunctions(tableValues)}
                    
                </tbody>
            </table>

        </>
    )
}


function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}

function sortingV2(list_of_dictionary,keyword,sortingOrder=true,key) {
    list_of_dictionary.sort((a, b) => {
        var previousValue = a[keyword]
        var nextValue = b[keyword]
        if(key){
            previousValue = previousValue[key]
            nextValue = nextValue[key]
        }
        if (sortingOrder===true){
            var reg = /^\d*\.?\d*$/;
            if(reg.test(previousValue) && reg.test(nextValue)){
                previousValue = parseFloat(previousValue)
                nextValue = parseFloat(nextValue)
            }
            if(typeof previousValue ==='number'){
                var temp = previousValue - nextValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((previousValue ? previousValue.toString().toLowerCase() : previousValue) < (nextValue ? nextValue.toString().toLowerCase() : nextValue)) return -1
                return (previousValue ? previousValue.toString().toLowerCase() : previousValue) > (nextValue ? nextValue.toString().toLowerCase() : nextValue) ? 1 : 0
            }
        }
        else{
            var reg = /^\d*\.?\d*$/;
            if(reg.test(previousValue) && reg.test(nextValue)){
                previousValue = parseFloat(previousValue)
                nextValue = parseFloat(nextValue)
            }
            if(typeof previousValue ==='number'){
                temp = nextValue - previousValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((nextValue ? nextValue.toString().toLowerCase() : nextValue) < (previousValue ? previousValue.toString().toLowerCase() : previousValue)) return -1
                return (nextValue ? nextValue.toString().toLowerCase() : nextValue) > (previousValue ? previousValue.toString().toLowerCase() : previousValue) ? 1 : 0
            }
            
        }
    })
}


export default TableModel