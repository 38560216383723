import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CampaignIcon from '@mui/icons-material/Campaign';
import AddCardIcon from '@mui/icons-material/AddCard';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import GetAppIcon from '@mui/icons-material/GetApp';
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

// import OfferPageV2 from '../pages/offer/v2/offerPageV2';
import AdvertiserPage from '../pages/advertiser/advertiserPage';
import AffiliatePage from '../pages/affiliates/affiliatePage';
import { TestingLinks } from '../pages/affiliates/testingLinks';
// import DemandSource from '../pages/demandSource/demandSource';
import DemandSource from '../pages/offerSync/demandSourceManagement/demandSource';
import EmployeePage from '../pages/employees/employees';
import SettingsPage from '../pages/settings/settings';
import Export from '../pages/export/export';
import ProfilePage from '../pages/profile/profile';
import CustomStats from '../pages/statistics/customStats';
// import { NavigationsAccount } from '../pages/navigations';


import NewAdvertiserPage from '../pages/advertiser/newAdvertiserPopup';
import EditAdvertiserPage from '../pages/advertiser/editAdvertiserPage';
import ViewAdvertiserPage from '../pages/advertiser/viewAdvertiserPage';
import NewAffiliatePage from '../pages/affiliates/newAffiliatePopup';
import EditAffiliatePage from '../pages/affiliates/editAffiliatePage';
import ViewAffiliatePage from '../pages/affiliates/viewAffiliatePage';
// import NewDemandSource from '../pages/demandSource/newDemandSource';
// import EditDemandSource from '../pages/demandSource/editDemandSource';
import DemandOffers from '../pages/offerSync/demandOffers/demandOffers';
import NewEmployeePage from '../pages/employees/newEmployeePage';
import ViewEmployeePage from '../pages/employees/viewEmployeePage';
import EditEmployeePage from '../pages/employees/editEmployeePage';
import DownloadPage from '../pages/export/downloadExport';

import ViewOfferPageV2 from '../pages/offer/v2/viewOfferPageV2';
import NewOfferPageV2 from '../pages/offer/v2/newOfferPageV2';
import DemandSourceV2 from '../pages/offerSync/demandSourceManagement/v2/demandSource';
import AddEditDemandSource from '../pages/offerSync/demandSourceManagement/v2/addEditDemandSource';
import EditAutomationV2 from '../pages/offerSync/automation/v2/editAutomations';
import StatisticsAll from '../pages/statistics/v2/statistics';
import ConversionStatistics from '../pages/statistics/v2/conversionStatistics';
import FailedPostbackStatistics from '../pages/statistics/v2/failedPostbackStatistics';
import DashboardPageV2 from '../pages/dashboardV2/dashboard';
import ManualRecheck from '../pages/automation/manualRecheck';
import AdvertiserPageV2 from '../pages/advertiser/v2/advertiserPageV2';
import NewEditAdvertiserPageV2 from '../pages/advertiser/v2/newEditAdvertiserPageV2';
import AffiliatePageV2 from '../pages/affiliates/v2/affiliatePageV2';
import OfferPageViewV3 from '../pages/offer/v3/offerPageView';
import NewEditAffiliatePageV2 from '../pages/affiliates/v2/newEditAffiliatePageV2';
import SmartLinks from '../pages/smartlinks/smartlinks';
import AddEditSmartLink from '../pages/smartlinks/addEditSmartLink';
import UserLogs from '../pages/users/userLogs';
import DayWiseOfferStats from '../pages/statistics/v2/dayWiseOfferStatistics';
import UsersPage from '../pages/users/users';
import AddEditUser from '../pages/users/addEditUser';
import AdvertiserBilling from '../pages/billing/advertiserBilling';
import AddAdvertiserBilling from '../pages/billing/addAdvertiserBilling';
import InvoiceDetails from '../pages/billing/invoiceDetails';
import AdvertiserBillingV2 from '../pages/billing/advertiserBillingV2';
import AdvertiserBillingSummary from '../pages/billing/advertiserBillingSummary';
import PackageNameBilling from '../pages/billing/packageNameBilling';
import InsightAdvertiserPage from '../pages/billing/insightAdvertiser';
import TrafficLimitedAdvertiser from '../pages/advertiser/v2/trafficLimitedAdvertisers';
import InsightsAdvertiserBilling from '../pages/billing/insightsAdvertiserBilling';
import TrafficLimitedAppWise from '../pages/offer/v3/trafficLimiterApp';
import RealTimeValidations from '../pages/billing/realTimeValidations';
import InsightsManik from '../pages/billing/insightsManik/App';
import Demography from '../pages/demography/demography';
import { getLocalStorageValue, getUserType, isSuperUser } from '../util/util';
import AccountManagerSummary from '../pages/accountManager/accountManagerSummary';
import ManagerDataEntry from '../pages/accountManager/managerDataEntry';
import { ElectricalServices, TrendingUp } from '@mui/icons-material';
import BackendService from '../pages/backendServices/backendService';
import AdopsSummary from '../pages/accountManager/adopsSummary';


import PaymentInsights from '../pages/billing/paymentInsights/App';
import RealTimeValidationAnalysis from '../pages/billing/realTimeValidationAnalysis';
// import UserConfiguration from '../pages/accountManager/userConfiguration';
// import NewOfferPageV2APIV2 from '../pages/offer/v2/newEditOfferPageV2';
// import CustomizedDashboard from '../pages/dashboardV2/customizedDashboard/customizedDashboard';

//// OLD VERSION
// import Dashboard from '../pages/dashboard';
// import DailyStats from '../pages/statistics/daily';
// import OfferStats from '../pages/statistics/offer';
// import AdvertiserStats from '../pages/statistics/advertiser';
// import AdvertiserManagerStats from '../pages/statistics/advertiserManager';
// import AffiliateStats from '../pages/statistics/affiliate';
// import AffiliateManagerStats from '../pages/statistics/affiliateManager';
// import ConversionStats from '../pages/statistics/conversions';
// import FailedPostback from '../pages/statistics/failedpostback';
// import GoalsStats from '../pages/statistics/goals';
// import OffersPage from '../pages/offer/offersPage';

// import AutomationDemandSource from '../pages/offerSync/automation/automation';

// import NewOfferPage from '../pages/offer/newOfferPopup';
// import EditOfferPage from '../pages/offer/editOfferPage';
// import ViewOfferPage from '../pages/offer/viewOfferPage';

// import NewDemandSource from '../pages/offerSync/demandSourceManagement/newDemandSource';
// import EditDemandSource from '../pages/offerSync/demandSourceManagement/editDemandSource';
// import EditAutomationOfferSync from '../pages/offerSync/automation/editautomation';
// import Trafficback from '../pages/statistics/trafficbackReason';


/// COOKIE 
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

export const isTestingUser = () => {
    return getLocalStorageValue('adminPanelLoginData') && getLocalStorageValue('adminPanelLoginData').userdata && (getLocalStorageValue('adminPanelLoginData').userdata.email==='developer@appanalytics.in' || getLocalStorageValue('adminPanelLoginData').userdata.email==='tester@appanalytics.in')
}

export const sidebarTabs = [
    {
        title :  "Dashboard",
        icon  : <DashboardIcon color="green" fontSize="medium"/>,
        link  : "/dashboard",
        page  : DashboardPageV2,
        // subNav : [
        //     {
        //         title : "Dasboard",
        //         link  : "/dashboard",
        //         page  : DashboardPageV2,
        //     },
        //     {
        //         title : "Customized Dasboard",
        //         link  : "/db/customized",
        //         page  : CustomizedDashboard,
        //     },
        // ],
        line  : 0,
    },
    ...(!getLocalStorageValue('statsV2') && process.env.REACT_APP_STATS_V4_ENABLED) ?  [{
        title :  "Statistics V4",
        icon  : <div style={{position:'relative'}}><BarChartIcon /><div style={{position:'absolute',top:0,right:'-5px',fontSize:'10px',fontWeight:'500'}}>v4</div></div>,
        link  : "/statistics",
        // page  : Home,
        line  : 300,
        subNav : [
            {
                title : "Daily",
                link  : "/statistics/daily",
                page  : Demography,
                requiredPermission:'view_dailystatistics',
            },
            {
                title : "Weekly",
                link  : "/statistics/weekly",
                page  : Demography,
                requiredPermission:'view_weeklystatistics',
            },
            {
                title : "Offers",
                link  : "/statistics/offer",
                page  : Demography,
                requiredPermission:'view_offerstatistics',
            },
            {
                title : "Package Name",
                link  : "/statistics/day-wise-offers",
                page  : Demography,
                requiredPermission:'view_daywiseoffersstatistics',
            },
            {
                title : "Advertiser",
                link  : "/statistics/adv",
                page  : Demography,
                requiredPermission:'view_advertiserstatistics',
            },
            {
                title : "Advertiser Manager",
                link  : "/statistics/adv-manager",
                page  : Demography,
                requiredPermission:'view_advertisermanagerstatistics',
            },
            {
                title : "Affiliate",
                link  : "/statistics/aff",
                page  : Demography,
                requiredPermission:'view_affiliatestatistics',
            },
            {
                title : "Affiliate Manager",
                link  : "/statistics/aff-manager",
                page  : Demography,
                requiredPermission:'view_affiliatemanagerstatistics',
            },
            {
                title : "Event Postback",
                link  : "/statistics/conversions/events",
                page  :  Demography,
                requiredPermission:'view_postbacksstatistics',
            },
            {
                title : "Postback",
                link  : "/statistics/conversions",
                page  :  Demography,
                requiredPermission:'view_postbacksstatistics',
            },{
                title : "Failed Postback",
                link  : "/statistics/failed/postback",
                page  :  Demography,
                requiredPermission:'view_failedpostbacksstatistics',
            },
            {
                title : "Goals",
                link  : "/statistics/goals",
                page  : Demography,
                requiredPermission:'view_goalsstatistics',
            },
        ]
    }] : [],
    ...(getLocalStorageValue('statsV2') || !process.env.REACT_APP_STATS_V4_ENABLED) ? [{
        title :  "Statistics",
        icon  : <BarChartIcon />,
        link  : "/statistics",
        // page  : Home,
        line  : 300,
        subNav :[
            {
                title : "Daily",
                link  : "/statistics/daily",
                page  : StatisticsAll,
                requiredPermission:'view_dailystatistics',
            },
            {
                title : "Weekly",
                link  : "/statistics/weekly",
                page  : StatisticsAll,
                requiredPermission:'view_weeklystatistics',
            },
            {
                title : "Offers",
                link  : "/statistics/offer",
                page  : StatisticsAll,
                requiredPermission:'view_offerstatistics',
            },
            {
                title : "Day Wise Offers",
                link  : "/statistics/day-wise-offers",
                page  : DayWiseOfferStats,
                requiredPermission:'view_daywiseoffersstatistics',
            },
            {
                title : "Advertiser",
                link  : "/statistics/adv",
                page  : StatisticsAll,
                requiredPermission:'view_advertiserstatistics',
            },
            {
                title : "Advertiser Manager",
                link  : "/statistics/adv-manager",
                page  : StatisticsAll,
                requiredPermission:'view_advertisermanagerstatistics',
            },
            {
                title : "Affiliate",
                link  : "/statistics/aff",
                page  : StatisticsAll,
                requiredPermission:'view_affiliatestatistics',
            },
            {
                title : "Affiliate Manager",
                link  : "/statistics/aff-manager",
                page  : StatisticsAll,
                requiredPermission:'view_affiliatemanagerstatistics',
            },
            {
                title : "Event Postback",
                link  : "/statistics/conversions/events",
                page  :  ConversionStatistics,
                requiredPermission:'view_postbacksstatistics',
            },
            {
                title : "Postback",
                link  : "/statistics/conversions",
                page  :  ConversionStatistics,
                requiredPermission:'view_postbacksstatistics',
            },
            {
                title : "Failed Postback",
                link  : "/statistics/failed/postback",
                page  :  FailedPostbackStatistics,
                requiredPermission:'view_failedpostbacksstatistics',
            },
            {
                title : "Smartlinks",
                link  : "/statistics/smartlinks",
                page  : StatisticsAll,
                requiredPermission:'view_smartlinksstatistics',
            },
            {
                title : "Goals",
                link  : "/statistics/goals",
                page  : StatisticsAll,
                requiredPermission:'view_goalsstatistics',
            },
            {
                title : "Trafficback",
                link  : "/statistics/trafficback",
                page  : StatisticsAll,
                requiredPermission:'view_trafficbackstatistics',
            },
            {
                title : "Custom",
                link  : "/statistics/custom",
                page  : CustomStats,
                requiredPermission:'view_customstatistics',
            },
        ]
    }] : [],
    ...(process.env.REACT_APP_STATS_V6_ENABLED) ? [{
        title :  "Statistics V6",
        icon  : <div style={{position:'relative'}}><BarChartIcon /><div style={{position:'absolute',top:0,right:'-5px',fontSize:'10px',fontWeight:'500'}}>v6</div></div>,
        link  : "/statisticsV6",
        // page  : Home,
        line  : 300,
        subNav :[
            {
                title : "Offers",
                link  : "/statisticsV6/offer",
                page  : Demography,
                requiredPermission:'view_offerstatistics',
            },
            // {
            //     title : "Day Wise Offers",
            //     link  : "/statisticsV6/day-wise-offers",
            //     page  : DayWiseOfferStats,
            //     requiredPermission:'view_daywiseoffersstatistics',
            // },
            {
                title : "Advertiser",
                link  : "/statisticsV6/adv",
                page  : Demography,
                requiredPermission:'view_advertiserstatistics',
            },
            {
                title : "Advertiser Manager",
                link  : "/statisticsV6/adv-manager",
                page  : Demography,
                requiredPermission:'view_advertisermanagerstatistics',
            },
            {
                title : "Affiliate",
                link  : "/statisticsV6/aff",
                page  : Demography,
                requiredPermission:'view_affiliatestatistics',
            },
            {
                title : "Affiliate Manager",
                link  : "/statisticsV6/aff-manager",
                page  : Demography,
                requiredPermission:'view_affiliatemanagerstatistics',
            }
        ]
    }] : [],
    {
        title :  "Offers",
        icon  : <LocalOfferIcon />,
        link  : "/offers",
        subNav : [
            {
                title : "Offers",
                link  : "/offers",
                page  : OfferPageViewV3,
            },
            {
                title : "Traffic Limiter (App Wise)",
                link  : "/app/trafficlimited",
                page  : TrafficLimitedAppWise,
            },
        ],
        line  : 7,
    },
    {
        title :  "Advertiser",
        icon  : <CampaignIcon />,
        link  : "/advertisers",
        page  : localStorage.getItem('oldAdvertiserPage') ? AdvertiserPage : AdvertiserPageV2,
        line  : 0,
        subNav : [
            {
                title : "Advertisers",
                link  : "/advertiser",
                page  : localStorage.getItem('oldAdvertiserPage') ? AdvertiserPage : AdvertiserPageV2,
            },
            {
                title : "Traffic Limiter",
                link  : "/trafficlimited",
                page  : TrafficLimitedAdvertiser,
            },
        ],
    },
    {
        title :  "Affiliates",
        icon  : <PeopleIcon />,
        link  : "/affiliates",
        page  : AffiliatePage,
        line  : 110,
        subNav : [
            {
                title : "Affiliate Management",
                link  : "/affiliates/management",
                page  :localStorage.getItem('oldAffiliatePage') ?  AffiliatePage : AffiliatePageV2,
            },
            {
                title : "Testing links",
                link  : "/affiliates/testinglink",
                page  : TestingLinks,
            },
        ],
    },
    {
        title :  "Offer Sync",
        icon  : <AddCardIcon />,
        link  : "/demandsource",
        page  : DemandSource,
        line  : 90,
        subNav : [
            {
                title : "Demand Source",
                link  : "/demandsource/management",
                page  : DemandSourceV2,
            },
            // {
            //     title : "Automation",
            //     link  : "/demandsource/automation",
            //     page  : DemandSourceV2,
            // },

            {
                title : "Demand Offers",
                link  : "/demandsource/demandoffers",
                page  : DemandOffers,
            },
            // {
            //     title : "View offers",
            //     link  : "/demandsource/viewoffers",
            //     page  : DemandSource,
            // },
        ],
    },

    {
        title :  "Smartlinks",
        icon  : <AttachmentIcon />,
        link  : "/smartlinks",
        line  : 90,
        page  : SmartLinks,
    },
    {
        title :  "Automation",
        icon  : <MotionPhotosAutoIcon />,
        link  : "/automation",
        line  : 90,
        subNav : [
            {
                title : "Recheck Offers",
                link  : "/automation/recheckoffer",
                page  : ManualRecheck,
            },
            
            // {
            //     title : "View offers",
            //     link  : "/demandsource/viewoffers",
            //     page  : DemandSource,
            // },
        ],
    },
    {
        title :  "User Management",
        icon  : <RecordVoiceOverIcon />,
        link  : "/account",
        redirect:"/account/employees",
        line  : 110,
        subNav : [
            {
                title : "Users",
                link  : "/account/employees",
                page  : localStorage.getItem('oldEmployeePage') ? EmployeePage : UsersPage,
            },
            {
                title : "User Logs",
                link  : "/account/logs",
                page  : UserLogs,
            },
            {
                title : "Settings",
                link  : "/account/settings",
                page  : SettingsPage,
            },
        ],
    },
    ...(process.env.REACT_APP_STATS_MANAGER_STATS==='live') ?  [
    {
        title :  "Manual Numbers",
        icon  : <TrendingUp />,
        link  : "/manager",
        line  : 0,
        subNav : [
            ...(process.env.REACT_APP_ADOPS_TEAM) ? [{
                title : "Adops Summary",
                link  : "/accountManager/adopsSummary",
                page  : AdopsSummary,
                requiredUserType:'general_manager',
            }] : [],
            {
                title : "Manual Dashboard",
                link  : "/manager/summary",
                page  : AccountManagerSummary,
                requiredPermission:'view_manager_stats',
            },
            {
                title : "Data Summary",
                link  : "/manager/data",
                page  : ManagerDataEntry,
                requiredPermission:'change_manager_stats',
            },
        ]
    }] : [],
    {
        title :  "Billing",
        icon  : <AddShoppingCartIcon />,
        link  : "/billing",
        line  : 90,
        subNav : [
            {
                title : "Insights Dashboard",
                link  : "/billing/insights",
                page  : InsightsManik,
            },
            {
                title : "Payment Insights",
                link  : "/billing/paymentInsights",
                page  : PaymentInsights,
            },
            {
                title : "Advertiser Summary",
                link  : "/billing/summary/advertiser",
                page  : AdvertiserBillingSummary,
            },
            {
                title : "Offer Summary",
                link  : "/billing/advertiser",
                page  : AdvertiserBillingV2,
            },
            {
                title : "App Name Summary",
                link  : "/billing/package",
                page  : PackageNameBilling,
            },
            {
                title : "Real Time Validation ",
                link  : "/billing/realtimevalidation",
                page  : RealTimeValidations,
            },
            {
                title : "Real Time Payments",
                link  : "/billing/realtimepayments",
                page  : RealTimeValidations,
            },
            // {
            //     title : "Affiliate",
            //     link  : "/billing/affiliate",
            //     page  : AdvertiserBilling,
            // },
        ]
    },
    {
        title :  "Export",
        icon  : <GetAppIcon />,
        link  : "/exportData",
        page  : Export,
    },
    ...(getUserType()==='general_manager' || isSuperUser()) ?  [
    {
        title :  "Backend Services",
        icon  : <ElectricalServices />,
        link  : "/backendServices",
        page  : BackendService,
    }] 
    : []
]


export const HelperTabs = [
    
    {
        title :  "Edit Offer",
        link  : "/offers/add",
        page  : NewOfferPageV2,
        // page  : NewOfferPage,
    },
    {
        title :  "Edit Offer",
        link  : "/offers/edit",
        page  : NewOfferPageV2,
        // page  : EditOfferPage,
    },
    {
        title :  "Edit Offer",
        link  : "/offers/view",
        page  : ViewOfferPageV2,
    },
    
    {
        title :  "Add Advertiser",
        link  : "/advertiser/add",
        page  : localStorage.getItem('oldAdvertiserPage') ? NewAdvertiserPage : NewEditAdvertiserPageV2,
    },
    {
        title :  "Edit Advertiser",
        link  : "/advertiser/edit",
        page  : localStorage.getItem('oldAdvertiserPage') ? EditAdvertiserPage : NewEditAdvertiserPageV2,
    },
    {
        title :  "Edit Advertiser",
        link  : "/advertiser/view",
        page  : ViewAdvertiserPage,
    },


    {
        title :  "Add Affiliate",
        link  : "/affiliates/management/add",
        page  : localStorage.getItem('oldAffiliatePage') ?  NewAffiliatePage : NewEditAffiliatePageV2,
    },
    {
        title :  "Edit Affiliate",
        link  : "/affiliates/management/edit",
        page  : localStorage.getItem('oldAffiliatePage') ? EditAffiliatePage : NewEditAffiliatePageV2,
    },
    {
        title :  "Edit Affiliate",
        link  : "/affiliates/management/view",
        page  : ViewAffiliatePage,
    },
    {
        title :  "Add Demand Source",
        link  : "/demandsource/management/add",
        page  : AddEditDemandSource,
        // page  : NewDemandSource,
    },
    {
        title :  "Edit Demand Source",
        link  : "/demandsource/management/edit",
        // page  :  EditDemandSource,
        page  : AddEditDemandSource,
    }, 
    {
       title :  "Edit Automation Offer Sync",
       link  : "/demandsource/automation/edit",
       page  : EditAutomationV2,
    //    page  : EditAutomationOfferSync,
    },
    {
        title :  "Add Employees",
        link  : "/account/employees/add",
        page  : localStorage.getItem('oldEmployeePage') ? NewEmployeePage : AddEditUser,
    },
    {
        title :  "View Employees",
        link  : "/account/employees/view",
        page  : ViewEmployeePage,
    },
    {
        title :  "Edit Employees",
        link  : "/account/employees/edit",
        page  : localStorage.getItem('oldEmployeePage') ? EditEmployeePage : AddEditUser,
    },
    {
        title :  "Download Tab",
        link  : "/exportData/downloadData/",
        page  : DownloadPage,
    },

    {
        title :  "Add Smartlink",
        link  : "/smartlinks/add/",
        page  : AddEditSmartLink,
    },
    {
        title :  "Edit Smartlink",
        link  : "/smartlinks/edit/",
        page  : AddEditSmartLink,
    },
    {
        title :  "Profile",
        link  : "/profile",
        page  : ProfilePage,
    },

    {
        title : "Billing-Advertiser-Add",
        link  : "/billing/advertiser/new",
        page  : AddAdvertiserBilling,
    },
    {
        title : "Billing-Advertiser-Add",
        link  : "/billing/affiliate/new",
        page  : AddAdvertiserBilling,
    },
    {
        title : "Billing-Advertiser-Add",
        link  : "/billing/affiliate/view",
        page  : InvoiceDetails,
    },
]