import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Constants from '../../data/constants';
import { getLocalStorageValue, sortingV2 } from '../../util/util';
import './backendService.css';
import TableModel from '../../components/tableModel/tablemodel';
import { CircularProgress } from '@mui/material';
import FilterRow from '../../components/filterV2/filtersRow';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { RoomService } from '@mui/icons-material';
import { Pagination } from '@mui/material';
import DbMeasurements from './dbMeasurements';

const BackendService = () => {
    
    const [tableValues, setTableValues] = useState([])
    const [loader, setLoader] = useState(false)
    const [useStateManual, setUseStateManual] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState(false)
    const [managerDict, setManagerDict] = useState(false)
    const [limit, setLimit] = useState(15)
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)
    
    const customChildComponent = (valueItem,childItem,childIndex,item) => {
        if(childItem.value === 'diff'){
            return <div>
                {parseInt(valueItem[item.value]['v2'] - valueItem[item.value]['v4'])}
            </div>
        }
        if(item.value === 'clicks' || item.value === 'trafficback'){
            return <div>
                {valueItem[item.value][childItem.value]}
            </div>
        }
        return <div>
            {valueItem[item.value][childItem.value] ? valueItem[item.value][childItem.value].toFixed(2) : valueItem[item.value][childItem.value]}
        </div>
    }
    
    const customStatusComponent = (valueItem,item) => {
        if(valueItem.status === 1){
            return <div style={{color:'green'}}>
                Matched
            </div>
        }
        return <div style={{color:'red'}}>
                   Not Matched
                </div>
    }
    
    const customDateTimeComponent = (valueItem,item) => {
        if(item.onlyDate){
            var localeOptions = {day:'2-digit',month:'short',year:'numeric'}
        }
        else{
            localeOptions = {day:'2-digit',month:'short',year:'numeric',hour:'2-digit',minute:'2-digit',second:'2-digit'}
        }
        if(valueItem[item.value]){
            return new Date(valueItem[item.value]).toLocaleString('en-US',).toUpperCase()
        }
        else{
            return '-'
        }
    }
    const customIsNullComponent = (valueItem,item) => {
        if(valueItem[item.value] || valueItem[item.value]===0){
            return valueItem[item.value]
        }
        else{
            return '-'
        }
    }
    
    const customGetEmployeeName = (valueItem,item) => {
        if(valueItem[item.value]){
            if(managerDict[valueItem[item.value]]){
                return managerDict[valueItem[item.value]]
            }
            return valueItem[item.value]
        }
        else{
            return '-'
        }
    }

    const customStatusDeletedComponent = (valueItem,item) => {
        if(valueItem.status === 1){
            return <div style={{color:'green'}}>
                Success
            </div>
        }
        return <div style={{color:'red'}}>
                   Failure
                </div>
    }

    const checkComponent = (valueItem,item) => {
        if(valueItem[item.value]){
            return <div style={{color:'green'}}>
                Yes
            </div>
        }
        return <div style={{color:'red'}}>
                   No
                </div>
    }
    var tabOptions = [
        // 0
        {label:'Demand Offers Service',value:'offer_scraper',
            alertMessage : 'Below data is related to the numbers of offers the backend service has fetched from the enabled Demand Sources.',
            tableHeaders : [
                {label:'Date',hideSorting:true,value:'date'},
                {label:'Total Offers',value:'total_offers',hideSorting:true,info:'Total Offers Fetched from demand sources.'},
                {label:'Active Offers',value:'live_offers',hideSorting:true,info:'Live Offers that are available in offers sections which were fetched on particular date.'},
                {label:'Published Offers',value:'published_offers',hideSorting:true,info:'Offers that are were fetched from API and added to offers section.'},
                {label:'Inactive Offers',value:'inactive_offers',hideSorting:true,info:'Offers that were inactive, which were available on Offers Section previously but not fetched from Demand Source.'},
                {label:'New Offers',value:'new_offers',hideSorting:true,info:'New Offers Fetched from Demand Source.'},
                {label:'Not Updating Offers',value:'not_updating_offers',hideSorting:true,info:'Offers whose data was not updated on demand source.'},
                {label:'Deleted Offers',value:'deleted_offers',hideSorting:true,info:'Offers Deleted from Offers Section.'},
                // {label:'Updating Offers',value:'updating_offers',hideSorting:true,info:'Offers whose data was updated on demand source.'},
            ]
        },
        // 1
        {label:'V2 vs V4 Service',value:'v2_v4_service',
            tableHeaders : [
                {label:'Date',locked:true,hideSorting:true,value:'date'},
                {label:'Status',value:'status',hideSorting:true,customComponent:customStatusComponent},
                {label:'Clicks',value:'clicks',width:200,child:[
                    {label:'V2',value:'v2',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'V4',value:'v4',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'Diff',value:'diff',hideSortingChild:true,customComponent:customChildComponent},
                ]},
                {label:'Trafficback',value:'trafficback',width:200,child:[
                    {label:'V2',value:'v2',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'V4',value:'v4',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'Diff',value:'diff',hideSortingChild:true,customComponent:customChildComponent},
                ]},
                {label:'Approved',value:'approved',width:200,child:[
                    {label:'V2',value:'v2',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'V4',value:'v4',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'Diff',value:'diff',hideSortingChild:true,customComponent:customChildComponent},
                ]},
                {label:'Declined',value:'declined',width:200,child:[
                    {label:'V2',value:'v2',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'V4',value:'v4',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'Diff',value:'diff',hideSortingChild:true,customComponent:customChildComponent},
                ]},
                {label:'Approved Revenue',value:'approved_revenue',width:200,child:[
                    {label:'V2',value:'v2',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'V4',value:'v4',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'Diff',value:'diff',hideSortingChild:true,customComponent:customChildComponent},
                ]},
                {label:'Declined Revenue',value:'declined_revenue',width:200,child:[
                    {label:'V2',value:'v2',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'V4',value:'v4',hideSortingChild:true,customComponent:customChildComponent},
                    {label:'Diff',value:'diff',hideSortingChild:true,customComponent:customChildComponent},
                ]},
                
            ]
        },
        // 2
        {label:'All Offer Service',value:'offer_section_service',
            tableHeaders : [
                {label:'Date',locked:true,hideSorting:true,value:'date'},
                {label:'Total Offers',value:'total_offers',hideSorting:true,info:'Total Offers on Panel'},
                {label:'Manual Offers',value:'manual_offers',hideSorting:true,info:'Manual Offers on Panel.'},
                {label:'Auto Offers',value:'auto_offers',hideSorting:true,info:'Offers that were fetched from various demand sources.'},
                {label:'Auto Active Offers',value:'auto_active_offers',hideSorting:true,info:'Offers that were fetched from demand source before and are in demand source API now.'},
                {label:'Auto Inactive Offers',value:'auto_inactive_offers',hideSorting:true,info:'Offers that were fetched from demand source before and were not found in demand source API now.'},
                {label:'Auto Inactive Offers(Stats)',value:'auto_inactive_stats_offers',hideSorting:true,info:'Offers that were fetched from demand source before and were not found in demand source API now.'},
                {label:'Auto Inactive(Non Stats) Offers',value:'auto_inactive_non_stats_offers',hideSorting:true,info:'Similar to Auto Inactive Offers but created no statistics on panel.'},
                {label:'Deleted Offers',value:'deleted_offers',hideSorting:true,info:'Offers that were deleted from panel.'},
            ]
        },
        // 3
        {
            label:'Postback Change Service',value:'postback_change_service',
            tableHeaders:[
                {label:'Created At',locked:true,hideSorting:true,value:'created_at',customComponent:customDateTimeComponent,info:'Postback Data changed date and time'},
                {label:'User ID',hideSorting:true,value:'employee_id',customComponent:customGetEmployeeName,info:'User who changed the postback data'},
                {label:'Status',hideSorting:true,value:'request_status',info:'Postback data changed request status'},
                {label:'Payout Status Changed to',hideSorting:true,value:'status',customComponent:customIsNullComponent,info:"User changed the postback status to"},
                {label:'Revenue Changed to',hideSorting:true,value:'revenue',customComponent:customIsNullComponent,info:"User changed the revenue to"},
                {label:'Payout Changed to',hideSorting:true,value:'payout',customComponent:customIsNullComponent,info:"User changed the payout to"},
                {label:'Count',hideSorting:true,value:'count',customComponent:customIsNullComponent,info:"Number of rows that user request changes to"},
            ]
        },
        // 4
        {
            label:'V2 Auto Deletion',value:'v2_auto_deletion',
            tableHeaders:[
                {label:'Action Date',locked:true,hideSorting:true,value:'action_date',onlyDate:true,info:'Date on which deletion process was done.'},
                {label:'Delete Date',hideSorting:true,value:'delete_date',info:'Date of data which service deleted.'},
                {label:'Status',hideSorting:true,value:'status',customComponent:customStatusDeletedComponent,info:'Status of deletion service'},
                {label:'V2 Partition Deleted',hideSorting:true,value:'isV2PartitionDeleted',customComponent:checkComponent,info:'V2 Table deleted or not'},
                {label:'V2-V4 Matching',hideSorting:true,value:'isV2V4matching',customComponent:checkComponent,info:'Whether data of v2 is matching with v4 or not'},
                {label:'V2-V4 Auto Synced',hideSorting:true,value:'isV2V4AutoSynced',customComponent:checkComponent,info:'Data was synced automatically or not'},
                {label:'Partition Name',hideSorting:true,value:'partition',customComponent:customIsNullComponent,info:'Partition Table Name in database'},
                {label:'V2 Total Rows',hideSorting:true,value:'v2TotalRows',customComponent:customIsNullComponent,info:'Total Rows in V2 Table'},
                {label:'Deleted Rows Count',hideSorting:true,value:'deletedRowsCount',customComponent:customIsNullComponent,info:'Status of deletion service'},
            ]
        },
        // 5
        {
            label:'Auto Inactive Offers',value:'auto_inactive_stats_offers',urlPath:"auto/inactivestatsoffer",
            tableHeaders:[
                {label:'Date',locked:true,hideSorting:true,value:'date',onlyDate:true},
                {label:'Auto Inactive Stats Offers',hideSorting:true,customComponent:customIsNullComponent,value:'autoInactiveCount',info:''},
                {label:'Auto Inactive Install Stats Offers',hideSorting:true,customComponent:customIsNullComponent,value:'autoInactiveInstallStatCount',info:''},
                {label:'Auto Inactive Clicks Stats Offers',hideSorting:true,customComponent:customIsNullComponent,value:'autoInactiveClickStatCount',info:''},
                {label:'Auto Inactive Clicks Recent',hideSorting:true,customComponent:customIsNullComponent,value:'autoInactiveClicks3mCount',info:''},
                {label:'Auto Inactive Clicks 3M+',hideSorting:true,customComponent:customIsNullComponent,value:'autoInactiveClicks3mPlusCount',info:''},
                {label:'Auto Inactive Clicks 6M+',hideSorting:true,customComponent:customIsNullComponent,value:'autoInactiveClicks6mPlusCount',info:''},
                {label:'Auto Inactive Clicks 1Y+',hideSorting:true,customComponent:customIsNullComponent,value:'autoInactiveClicks1yPlusCount',info:''},
                {label:'Archived Offers',hideSorting:true,customComponent:customIsNullComponent,value:'archivedCount',info:''},
                {label:'Unarchived Offers',hideSorting:true,customComponent:customIsNullComponent,value:'unarchivedCount',info:''},
            ]
        },
        // 6
        {
            label:'DB BreakDown',value:'dbSizebreakdown',urlPath:"dbSizebreakdown",
        },
        // 7
        {
            label:'Click Auto Delete',value:'click_auto_delete',urlPath:"clickautodelete",
            tableHeaders:[
                {label:'Action Date',locked:true,hideSorting:true,value:'action_date',onlyDate:true,info:'Date on which deletion process was done.'},
                {label:'Delete Date',hideSorting:true,value:'delete_date',info:'Date of data which service deleted.'},
                {label:'Status',hideSorting:true,value:'status',customComponent:customStatusDeletedComponent,info:'Status of deletion service'},
                {label:'V2 Partition Deleted',hideSorting:true,value:'isClickPartitionDeleted',customComponent:checkComponent,info:'V2 Table deleted or not'},
                {label:'Partition Name',hideSorting:true,value:'partition',customComponent:customIsNullComponent,info:'Partition Table Name in database'},
                {label:'Deleted Rows Count',hideSorting:true,value:'deletedRowsCount',customComponent:customIsNullComponent,info:'Status of deletion service'},
            ]
        },
        // 8
        {
            label:'V4 Auto Deletion',value:'v4_auto_deletion',urlPath:"v4autodelete",
            tableHeaders:[
                {label:'Action Date',locked:true,hideSorting:true,value:'date',onlyDate:true,info:'Date on which deletion process was done.'},
                {label:'Checked Month',hideSorting:true,value:'month',onlyDate:true},
                {label:'Status',hideSorting:true,value:'status',customComponent:customStatusDeletedComponent},
                {label:'v6 Data Present',hideSorting:true,value:'isV6Present',customComponent:checkComponent},
                {label:'v6 Data Already Synced',hideSorting:true,value:'isV6DataAlreadySynced',customComponent:checkComponent},
                {label:'v6 Data Updated Required',hideSorting:true,value:'isV6DataUpdated',customComponent:checkComponent},
                {label:'v4-v6 Matching',hideSorting:true,value:'isV4V6Matching',customComponent:checkComponent},
                {label:'v4 Partition Deleted',hideSorting:true,value:'isV4PartitionDeleted',customComponent:checkComponent},
                {label:'Partition Name',hideSorting:true,value:'partitions',customComponent:customIsNullComponent,info:'Partition Table Name in database'},
                {label:'V4 total rows ',hideSorting:true,value:'v4TotalRows',customComponent:customIsNullComponent,info:'V4 Total Rows'},
                {label:'Deleted Rows Count',hideSorting:true,value:'deletedRowsCount',customComponent:customIsNullComponent,info:'Status of deletion service'},
            ]
        },
        // 9
        {
            label:'Backup Auto Deletion',value:'backupautodelete',urlPath:"backupautodelete",sortBy:'action_date',
            tableHeaders:[
                {label:'Action Date',locked:true,hideSorting:true,value:'action_date',onlyDate:true,info:'Date on which deletion process was done.'},
                {label:'Delete Date',hideSorting:true,value:'delete_date',info:'Date of data which service deleted.'},
                {label:'Status',hideSorting:true,value:'status',customComponent:customStatusDeletedComponent,info:'Status of deletion service'},
                {label:'Backup Partition Deleted',hideSorting:true,value:'isBackupDeleted',customComponent:checkComponent,info:'Backup Partition Table deleted or not'},
                {label:'Partition Name',hideSorting:true,value:'partition',customComponent:customIsNullComponent,info:'Partition Table Name in database'},
                {label:'Total Rows Count',hideSorting:true,value:'totalRowsCount',customComponent:customIsNullComponent,info:'Total Rows in partition'},
                {label:'Deleted Rows Count',hideSorting:true,value:'deletedRowsCount',customComponent:customIsNullComponent,info:'Rows Deleted'},
            ]
        },
        // 10
        {
            label:'Installs Partition Auto Deletion',value:'installspartition_auto_delete',operation:'installspartition_auto_delete',urlPath:"backupautodelete",sortBy:'action_date',
            tableHeaders:[
                {label:'Action Date',locked:true,hideSorting:true,value:'action_date',onlyDate:true,info:'Date on which deletion process was done.'},
                {label:'Delete Date',hideSorting:true,value:'delete_date',info:'Date of data which service deleted.'},
                {label:'Status',hideSorting:true,value:'status',customComponent:customStatusDeletedComponent,info:'Status of deletion service'},
                {label:'Partition Deleted',hideSorting:true,value:'isBackupDeleted',customComponent:checkComponent,info:'Partition Table deleted or not'},
                {label:'Partition Name',hideSorting:true,value:'partition',customComponent:customIsNullComponent,info:'Partition Table Name in database'},
                {label:'Total Rows Count',hideSorting:true,value:'totalRowsCount',customComponent:customIsNullComponent,info:'Total Rows in partition'},
                {label:'Deleted Rows Count',hideSorting:true,value:'deletedRowsCount',customComponent:customIsNullComponent,info:'Rows Deleted'},
            ]
        },
        // 11
        {
            label:'Trafficback Partition Auto Deletion',value:'trafficbackpartition_auto_delete',operation:'trafficbackpartition_auto_delete',urlPath:"backupautodelete",sortBy:'action_date',
            tableHeaders:[
                {label:'Action Date',locked:true,hideSorting:true,value:'action_date',onlyDate:true,info:'Date on which deletion process was done.'},
                {label:'Delete Date',hideSorting:true,value:'delete_date',info:'Date of data which service deleted.'},
                {label:'Status',hideSorting:true,value:'status',customComponent:customStatusDeletedComponent,info:'Status of deletion service'},
                {label:'Partition Deleted',hideSorting:true,value:'isBackupDeleted',customComponent:checkComponent,info:'Partition Table deleted or not'},
                {label:'Partition Name',hideSorting:true,value:'partition',customComponent:customIsNullComponent,info:'Partition Table Name in database'},
                {label:'Total Rows Count',hideSorting:true,value:'totalRowsCount',customComponent:customIsNullComponent,info:'Total Rows in partition'},
                {label:'Deleted Rows Count',hideSorting:true,value:'deletedRowsCount',customComponent:customIsNullComponent,info:'Rows Deleted'},
            ]
        },
        // 12
        {
            label:'Impression Partition Auto Deletion',value:'impressionspartition_auto_delete',operation:'impressionspartition_auto_delete',urlPath:"backupautodelete",sortBy:'action_date',
            tableHeaders:[
                {label:'Action Date',locked:true,hideSorting:true,value:'action_date',onlyDate:true,info:'Date on which deletion process was done.'},
                {label:'Delete Date',hideSorting:true,value:'delete_date',info:'Date of data which service deleted.'},
                {label:'Status',hideSorting:true,value:'status',customComponent:customStatusDeletedComponent,info:'Status of deletion service'},
                {label:'Partition Deleted',hideSorting:true,value:'isBackupDeleted',customComponent:checkComponent,info:'Partition Table deleted or not'},
                {label:'Partition Name',hideSorting:true,value:'partition',customComponent:customIsNullComponent,info:'Partition Table Name in database'},
                {label:'Total Rows Count',hideSorting:true,value:'totalRowsCount',customComponent:customIsNullComponent,info:'Total Rows in partition'},
                {label:'Deleted Rows Count',hideSorting:true,value:'deletedRowsCount',customComponent:customIsNullComponent,info:'Rows Deleted'},
            ]
        }
    ]
    
    if(!process.env.REACT_APP_ADOPS_TEAM && !process.env.REACT_APP_DB_BREAKDOWN){
        tabOptions.splice(6, 1)
    }
    if(!process.env.REACT_APP_OFFER_FETCH_API_V2){
        tabOptions.splice(5, 1)
    }
    if(!process.env.REACT_APP_DELETION_V2_PARTITION){
        tabOptions.splice(4, 1)
    }
    if(!process.env.REACT_APP_OFFERS_SECTIONS_SERVICE){
        tabOptions.splice(2, 1)
    }
    if(!process.env.REACT_APP_STATS_V4_ENABLED){
        tabOptions.splice(1, 1)
    }
    if(!process.env.REACT_APP_OFFER_FETCH_API_V2){
        tabOptions.splice(0, 1)
    }
    const [activeTab, setActiveTab] = useState(tabOptions.length > 0 ? tabOptions[0] : false)

    var defaultFilterData = {
        from_date:new Date(new Date().getTime() - 86400000*6),
        end_date:new Date(new Date().getTime()),
        backend_service:tabOptions.length > 0 ? tabOptions[0] : false
    }
    const [filterData, setFilterData] = useState({...defaultFilterData})

    const getBackendServiceData = async(manual_value=null,manual_limit=null,manual_page=null) => {
        setLoader(true)

        var from_date = filterData.from_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var end_date = filterData.end_date.toLocaleString('fr-CA',{year:'numeric',month:'numeric',day:'numeric'})
        var updateActiveTab = true
        if(filterData.backend_service.value === 'offer_scraper' || manual_value === 'offer_scraper'){
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"offer/scrapper/stats"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params:{
                    start_date:from_date,
                    end_date:end_date
                }
            })
            .then(resp => {
                if(resp.data.result){
                    var data = resp.data.result
                    sortingV2(data,'date',false)
                    setTableValues(data)
                    setLastUpdatedAt(resp.data.last_updated_at)
                }
                // setShowLoader(false)
            })
            .catch(err => {
                setTableValues([])
                console.error(err);
            })
        }

        else if(filterData.backend_service.value === 'v2_v4_service' || manual_value === 'v2_v4_service'){
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"backendv2v4service"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params:{
                    startDate:from_date,
                    endDate:end_date
                }
            })
            .then(resp => {
                if(resp.data.result){
                    var data = resp.data.result
                    sortingV2(data,'date',false)
                    setTableValues(data)
                    setLastUpdatedAt(resp.data.last_updated_at)
                }
                // setShowLoader(false)
            })
            .catch(err => {
                setTableValues([])
                console.error(err);
            })
        }

        else if(filterData.backend_service.value === 'offer_section_service' || manual_value === 'offer_section_service'){
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"service/offersummary"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params:{
                    start_date:from_date,
                    end_date:end_date
                }
            })
            .then(resp => {
                if(resp.data.result){
                    var data = resp.data.result
                    sortingV2(data,'date',false)
                    setTableValues(data)
                    setLastUpdatedAt(resp.data.last_updated_at)
                }
                // setShowLoader(false)
            })
            .catch(err => {
                setTableValues([])
                console.error(err);
            })
        }

        else if(filterData.backend_service.value === 'postback_change_service' || manual_value === 'postback_change_service'){
            if(manual_limit){
                setLimit(manual_limit)
            }
            if(manual_limit){
                setPage(manual_page)
            }
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"conversions/update/view"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params:{
                    startDate:from_date,
                    endDate:end_date,
                    limit:manual_limit ? manual_limit : limit,
                    page:manual_page ? manual_page : page
                }
            })
            .then(resp => {
                if(resp.data.result){
                    var data = resp.data.result
                    sortingV2(data,'date',false)
                    setTableValues(data)
                    setLastUpdatedAt(resp.data.last_updated_at)
                    setTotalResults(resp.data.count)
                }
                // setShowLoader(false)
            })
            .catch(err => {
                console.error(err);
                setTableValues([])
            })
        }

        else if(filterData.backend_service.value === 'v2_auto_deletion' || manual_value === 'v2_auto_deletion'){
            if(manual_limit){
                setLimit(manual_limit)
            }
            if(manual_limit){
                setPage(manual_page)
            }
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,"v2autodelete"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params:{
                    startDate:from_date,
                    endDate:end_date,
                    limit:manual_limit ? manual_limit : limit,
                    page:manual_page ? manual_page : page
                }
            })
            .then(resp => {
                if(resp.data.result){
                    var data = resp.data.result
                    sortingV2(data,'action_date',false)
                    setTableValues(data)
                    setLastUpdatedAt(resp.data.last_updated_at)
                    setTotalResults(resp.data.count)
                    
                }
                // setShowLoader(false)
            })
            .catch(err => {
                console.error(err);
                setTableValues([])
            })
        }
        else if(filterData.backend_service.value === 'dbSizebreakdown' || manual_value === 'dbSizebreakdown'){
            if(process.env.REACT_APP_ADOPS_TEAM){
                var otherEnvs = JSON.parse(process.env.REACT_APP_ADOPS_TEAM)
            }
            if(process.env.REACT_APP_CHANNEL_SYMBOL==='MA'){
                otherEnvs = [{"channel":"MA","backend":"https://backend.mobiaviator.com"}]
            }
            if(process.env.REACT_APP_CHANNEL_SYMBOL==='QM'){
                otherEnvs = [{"channel":"QM","backend":"https://backend.quasarmobi.com"}]
            }
            var tempTableValues = {}
            for(var index in otherEnvs){
                if(index<=2){
                    var item = otherEnvs[index]
                    tempTableValues[item.channel]={}
                    await axios({
                        method: 'GET',
                        url: "".concat(item.backend,Constants.API_USER_PATH,filterData.backend_service.urlPath),
                        headers:{
                            "Auth":"Authorization "+(process.env.REACT_APP_API_KEY_TOKEN ? process.env.REACT_APP_API_KEY_TOKEN : 'f5643bd6fd24dcfd3d63379e15628746ccedbc42ef57d94c9c7e957954fedaca')
                        },
                        params:{
                            startDate:from_date,
                            endDate:end_date,
                        }
                    })
                    .then(resp => {
                        if(resp.data){

                            tempTableValues[item.channel] = resp.data
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
                }
            }
            setTableValues(tempTableValues)
        }
        else{
            updateActiveTab = false
            if(manual_limit){
                setLimit(manual_limit)
            }
            if(manual_limit){
                setPage(manual_page)
            }
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL2,Constants.HOST2,Constants.API_USER_PATH,filterData.backend_service.urlPath),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params:{
                    startDate:from_date,
                    endDate:end_date,
                    limit:manual_limit ? manual_limit : limit,
                    page:manual_page ? manual_page : page,
                    operation:filterData.backend_service.operation ? filterData.backend_service.operation : undefined,
                }
            })
            .then(resp => {
                if(resp.data.result){
                    var data = resp.data.result
                    if(filterData.backend_service.sortBy){
                        sortingV2(data,filterData.backend_service.sortBy,false)
                    }
                    else{
                        sortingV2(data,'date',false)
                    }
                    setTableValues(data)
                    setLastUpdatedAt(resp.data.last_updated_at)
                    setTotalResults(resp.data.count)
                    if(filterData.backend_service.value === 'auto_inactive_stats_offers' || manual_value === 'auto_inactive_stats_offers'){
                        var tempTableHeaders = filterData.backend_service.tableHeaders
                        var newTableHeaders = []
                        for(var tableIndex in tempTableHeaders){
                            var add = false
                            var tableItem = tempTableHeaders[tableIndex]
                            for(var index in data){
                                var item = data[index]
                                if(item[tableItem.value]){
                                    add = true
                                    break
                                }
                            }
                            if(add){
                                newTableHeaders.push(tableItem)
                            }
                        }
                        var backend_service_filterdata = filterData.backend_service
                        backend_service_filterdata.tableHeaders = newTableHeaders
                        filterData.backend_service = backend_service_filterdata
                        setFilterData({...filterData,backend_service:backend_service_filterdata})
                        // setActiveTab(backend_service_filterdata)
                    }
                }
                // setShowLoader(false)
            })
            .catch(err => {
                setActiveTab(filterData.backend_service)
                console.error(err);
                setTableValues([])
            })
        }
        setActiveTab(filterData.backend_service)


        setLoader(false)

    };

    if(tabOptions.length===0){
        return <div style={{display:'flex',alignItems:'center',height:'80vh',justifyContent:'center'}}>
            Currently no backend service running.
        </div>
    }

    const getManager = async() => {
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"manager/details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let managerDict = {}
            for (var item in resp.data.result){
                managerDict[Object.keys(resp.data.result[item])[0]] = resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0]
            }
            setManagerDict(managerDict)
        })
        .catch(err => {
            console.error(err);
            return []
        })
    };

    if(!useStateManual){
        getManager()
        getBackendServiceData()
        setUseStateManual(true)
    }

    const dummyComponent = () => {
        return <div className='v2filterItemContainer' style={{width:'calc(20% - 4px)',background:'white'}}>
            </div>
    }
    var filtersList = [
        {label:'Date Range',paramKey:'dateRange',value:'date_range',type:'daterangepicker',icon:DateRangeIcon},
        {label:'Backend Service',paramKey:'backendService',value:'backend_service',type:'select',options:tabOptions,hideValueInLabel:true,icon:RoomService},
        {label:'Submit Reset Button',type:'submit_reset_button',onSubmit:()=>{getBackendServiceData(null,limit,1)},onReset:()=>{setFilterData(defaultFilterData)}},
        {label:'',paramKey:'',value:'',type:'',customComponent:dummyComponent},
        {label:'',paramKey:'',value:'',type:'',customComponent:dummyComponent},
    ]
    
    const overrideSetFilterData = (value,filterItem) => {
        if(filterItem==='dateRangePicker'){
            setFilterData({...filterData,...value})
        }
        else{
            setFilterData({...filterData,[filterItem.value]:value})
        }
    }
    return (
        <div className='backendServiceContainer'>
            <div className='headerLabel'>Backend Services Status</div>
            <br/>
            <FilterRow
            filtersList={filtersList} filterData={filterData} setFilterData={overrideSetFilterData}
            />
            {   
                !loader 
                &&
                activeTab.value === 'dbSizebreakdown' 
                &&
                <DbMeasurements loader={loader} filterData={filterData} tableValues={tableValues}/>
    
            }
            {
                loader
                &&
                <CircularProgress sx={{fontSize:'14px',color:localStorage.getItem('ambientColor')}}/>
            }
            {
                lastUpdatedAt &&
                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',fontSize:'12px'}}>
                    Last Updated At: 
                    {new Date(lastUpdatedAt).toLocaleString('en-GB')}
                </div>
            }
            {
                activeTab && activeTab.alertMessage
                &&
                <div style={{marginTop:'20px',fontSize:'12px'}}>{filterData.backend_service.alertMessage}</div>
            }
            <div style={{width:'100%',overflow:'auto',marginTop:'30px'}}>     
            {
                !loader &&
                activeTab.value !== 'dbSizebreakdown' 
                &&
                <TableModel
                    serialOffset={(page-1)*limit}
                    tableHeaders={activeTab.tableHeaders}
                    tableValues={tableValues}
                    valueRowHeight={'40px'}
                    customTableSettingsButton={true}
                />
            }
            </div>
            {activeTab.value !== 'dbSizebreakdown' && !loader && totalResults/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:1000}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getBackendServiceData(null,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,10,15,50,100,200,300,500].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getBackendServiceData(null,limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
        </div>
    )
}

export default BackendService