import { useState } from "react";
import parse from 'html-react-parser';
import { sidebarTabs } from "../data/sidebarTabs";
var CryptoJS = require("crypto-js");

export function capitalize(word) {
    if (word!==""){
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
    }
    return word
    }

export function sorting(dictionary,keyword,int_value=false,sortingOrder=true) {
    dictionary.sort((a, b) => {
        if (sortingOrder===true){
            if (int_value){
                return a[keyword] - b[keyword];
            }
            if ((a[keyword] ? a[keyword].toLowerCase() : a[keyword]) < (b[keyword] ? b[keyword].toLowerCase() : b[keyword])) return -1
            return (a[keyword] ? a[keyword].toLowerCase() : a[keyword]) > (b[keyword] ? b[keyword].toLowerCase() : b[keyword]) ? 1 : 0
        }
        else{
            if (int_value){
                return b[keyword] - a[keyword];
            }
            if ((b[keyword] ? b[keyword].toLowerCase() : b[keyword]) < (a[keyword] ? a[keyword].toLowerCase() : a[keyword])) return -1
            return (b[keyword] ? b[keyword].toLowerCase() : b[keyword]) > (a[keyword] ? a[keyword].toLowerCase() : a[keyword]) ? 1 : 0
        }
    })
}

export function sortingV2(dictionary,keyword,sortingOrder=true,key) {
    dictionary.sort((a, b) => {
        var previousValue = a[keyword]
        var nextValue = b[keyword]
        if(key){
            previousValue = previousValue[key]
            nextValue = nextValue[key]
        }
        if (sortingOrder===true){
            if(typeof previousValue ==='number'){
                var temp = previousValue - nextValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((previousValue ? previousValue.toString().toLowerCase() : previousValue) < (nextValue ? nextValue.toString().toLowerCase() : nextValue)) return -1
                return (previousValue ? previousValue.toString().toLowerCase() : previousValue) > (nextValue ? nextValue.toString().toLowerCase() : nextValue) ? 1 : 0
            }
        }
        else{
            if(typeof previousValue ==='number'){
                temp = nextValue - previousValue;
                return temp
            }
            else{
                if(!previousValue && previousValue!==0){
                    previousValue = ''
                }
                if(!nextValue && nextValue!==0){
                    nextValue = ''
                }
                if ((nextValue ? nextValue.toString().toLowerCase() : nextValue) < (previousValue ? previousValue.toString().toLowerCase() : previousValue)) return -1
                return (nextValue ? nextValue.toString().toLowerCase() : nextValue) > (previousValue ? previousValue.toString().toLowerCase() : previousValue) ? 1 : 0
            }
            
        }
    })
}


export async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export function encryptData(message){
    var encrypted = CryptoJS.AES.encrypt(message, 'taste').toString()
    return encrypted;
}
export function decryptData(message){
    var bytes = CryptoJS.AES.decrypt(message, 'taste');
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

export function getLocalStorageValue(key){
    try{
        var keyEncrypted = CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex)
        var encryptedValue = localStorage.getItem(keyEncrypted)
        var decryptedData = decryptData(encryptedValue)
        try{
            decryptedData = JSON.parse(decryptedData)
        }
        catch{
            decryptedData = decryptedData.toString()
        }
        return decryptedData
    }
    catch{
        if(localStorage.getItem(key)){
            decryptedData = localStorage.getItem(key)
            try{
                decryptedData = JSON.parse(decryptedData)
            }
            catch{
                decryptedData = decryptedData.toString()
            } 
            return decryptedData
        }
        return false
    }
}

export function zeroPad(num, numZeros) {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
}

export function getUserType(){
    var data = getLocalStorageValue('adminPanelLoginData')
    if(data && data.userdata && data.userdata && data.userdata.employee_type){
        return data.userdata.employee_type
    }
    return true
}


export function getUserEmail(){
    var data = getLocalStorageValue('adminPanelLoginData')
    if(data && data.userdata && data.userdata && data.userdata.email){
        return data.userdata.email
    }
    return true
}

export function checkForPermission(permission){
    var data = getLocalStorageValue('adminPanelLoginData')
    if(data && data.userdata && data.userdata && ((data.userdata.permissions && data.userdata.permissions[permission]) || data.userdata.superuser)){
        return true
    }
    return false
}
export function isSuperUser(){
    var data = getLocalStorageValue('adminPanelLoginData')
    if(data && data.userdata && data.userdata && (data.userdata.superuser)){
        return true
    }
    return false
}
export function removeLocalStorageValue(key){
    if(localStorage.getItem(key)){
        localStorage.removeItem(key)
    }
    var keyEncrypted = CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex)
    if(!localStorage.getItem(keyEncrypted)){
        keyEncrypted = key
    }
    localStorage.removeItem(keyEncrypted)
}
export function setLocalStorageValue(key,data){
    if(true || window.location.protocol==='http:'){
        localStorage.setItem(key,JSON.stringify(data))
    }
    else{
        var keyEncrypted = CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex)
        if(typeof data === 'object'){
            data = JSON.stringify(data)
        }
        var dataEncrypted = encryptData(data)
        localStorage.setItem(keyEncrypted,dataEncrypted)
    }
}

export function getDateFromDateRange(dateRange){
    var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
    var string = ""
    if(dateRange.length>1){
        string += new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        string += " -> "
        string += new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)
    }
    return string
}

export const getRequests = async(method,setMethod) => {
    var resp = await method;
    setMethod(resp)
}

export function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}


export const showHtml = (offerKpiManual) => {
    try{
        while(offerKpiManual.includes("&lt;") || offerKpiManual.includes("&gt;")){
            offerKpiManual = offerKpiManual.replace("&lt;","<")
            offerKpiManual = offerKpiManual.replace("&gt;",">")
        }
        return parse(offerKpiManual)
    }
    catch{
        return offerKpiManual
    }
}


export function getElementCoords(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}

export function getBase64FromFile(file){
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
};

export function bytesToSize(x) {
    try{
        var units = ['B', 'KB', 'MB', 'GB', 'TB'],
            bytes = x,
            i;
        
        for (i = 0; bytes >= 1024 && i < 4; i++) {
            bytes /= 1024;
        }
        
        return bytes.toFixed(2) + ' ' + units[i];
    }
    catch{
        return x
    }
}




export function abbreviateNumber(number){
    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}


export const sidebartabAllowed = (link,item=null) => {
    var allowedPages = getLocalStorageValue('allowedPages')  ?getLocalStorageValue('allowedPages') : []
    if(getUserType()==='general_manager' && link.includes('/backendServices')){
        return true
    }
    if(isSuperUser()){
        return true
    }
    if(item && item.requiredUserType  && (item.requiredUserType === getUserType())){
        return true
    }
    if(link.includes('/statistic')){
        for(var xxIndex in sidebarTabs){
            var xxItem = sidebarTabs[xxIndex]
            if(xxItem.link==='/statistics'){
                for(var subnavIndex in xxItem.subNav){
                    var subnavItem = xxItem.subNav[subnavIndex];
                    if(allowedPages.includes('page'+subnavItem.link.replaceAll('/','_')) && subnavItem.link.includes(link.split('/statistic')[1])){
                        return true
                    }
                }
            }
        }
    }
    if((getLocalStorageValue('adminPanelLoginData') && getLocalStorageValue('adminPanelLoginData').userdata && getLocalStorageValue('adminPanelLoginData').userdata.email==='developer@appanalytics.in') || getLocalStorageValue('adminPanelLoginData').superuser || allowedPages.includes('page'+link.replaceAll('/','_'))){
        return true
    }
    if(getLocalStorageValue('adminPanelLoginData') && getLocalStorageValue('adminPanelLoginData').userdata  && getLocalStorageValue('adminPanelLoginData').userdata.permissions){
        var ppp = Object.keys(getLocalStorageValue('adminPanelLoginData').userdata.permissions)
        if(item){
            if(ppp.includes(item.requiredPermission)){
                return true
            }
        }
    }
    if(item && item.subNav){
        for(var subnavIndex in item.subNav){
            var subnavItem = item.subNav[subnavIndex];
            if(getLocalStorageValue('adminPanelLoginData') && getLocalStorageValue('adminPanelLoginData').userdata  && getLocalStorageValue('adminPanelLoginData').userdata.permissions){
                var ppp = Object.keys(getLocalStorageValue('adminPanelLoginData').userdata.permissions)
                if(ppp.includes(subnavItem.requiredPermission)){
                    return true
                }
            }
            if(allowedPages.includes('page'+subnavItem.link.replaceAll('/','_'))){
                return true
            }
        }
    }
}